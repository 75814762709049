import React, {FC, Fragment, useState} from 'react'
import {Checkbox, Radio} from "../../../ui-components";
import styles from "./styles.module.scss";
import {AmbulanceIcon, LoaderIcon} from "../../../assets";
import {InfoText, Spinner} from "../../../shared";
import {useAppSelector} from "../../../core/hooks";
import { PARTNERSHIPS_PRIORITY_TEXTS } from '../../../services/constants'

interface Props {
  selectedOffer: string
  handleSelectChange: (request_uuid) => void
}

const OffersList: FC<Props> = (props) => {
  const {pools, request} = useAppSelector(state => state.requests)

  let offers: any = []

  for(const offer of pools) {
    if(offer.status === 2) {
      const brigades = offer.request_brigade.map((brigade) => {
        return {...brigade, orgName: offer.organization.name, priority: offer.connection?.priority ? PARTNERSHIPS_PRIORITY_TEXTS[offer.connection?.priority - 1].type : 'Нет информации' }
      })

      offers.push(...brigades)
    }
  }

  let list: any;

  if (request.status === 3) {
    list = offers.map((el, i) =>
      <div key={i}
           className={`${styles.offerCard} ${el.approved_by ? styles.approved : styles.disabled}`}>
        {el.approved_by && <Spinner />}
        <div className={styles.infoWrapper}>
          <p className={styles.textMain}>{el.orgName}</p>
          <div className={styles.wrapper}>
            <InfoText className={styles.infoText} title={'Приоритет'} text={el.priority}/>
            <InfoText className={styles.infoText} title={'Тип Бригады'} text={el.brigade_type}/>
          </div>
        </div>
        <div className={styles.time}>{el.eta} мин</div>
      </div>)
  } else {
    list = offers.map((el, i) =>
      <div key={i}
           onClick={() => props.handleSelectChange(el)}
           className={`${styles.offerCard} ${props.selectedOffer === el.uuid ? styles.active : ''}`}>
        <Radio checked={props.selectedOffer === el.uuid} className={styles.radio} name='ambulance'/>
        <div className={styles.infoWrapper}>
          <p className={styles.textMain}>{el.orgName}</p>
          <div className={styles.wrapper}>
            <InfoText className={styles.infoText} title={'Приоритет'} text={el.priority}/>
            <InfoText className={styles.infoText} title={'Тип Бригады'} text={el.brigade_type}/>
          </div>
        </div>
        <div className={styles.time}>{el.eta} мин</div>
      </div>)
  }

  return (
    <div id={styles.offersContainer}>
      <p className={styles.title}><AmbulanceIcon/> Выбрать Скорую</p>
      {list}
    </div>
  )
}

export default OffersList
