import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {Requests, ValidationData, FullRequest, RequestsList, Message, Activity, SearchData, Booking} from './IRequests'
import {formValidator} from "./validator";
import {setData, toggleMainPanel, togglePopover, toggleRequestForm} from "../../app/app-slice";
import {getInsuranceRequestsList} from "./insurance-thunk";
import {changeDateFormat, downloadFile} from "../../services/utils";
import moment from "moment";
import {getErRequestsList} from "./er-thunk";
import {pick} from 'lodash'

const initialState: Requests = {
  request: {
    uuid: '',
    first_name: 'bob',
    last_name: 'dylan',
    middle_name: 'mlei',
    sex: 1,
    birthday: '',
    complaints: 'bla bla bla',
    request_number: '',
    request_reason: '',
    type: 1,
    policy_number: 'er12312w',
    contact_number: '55523434',
    age: 0,
    city: '',
    address: 'address',
    status: 0,
    scheduled_date: null,
    sentry_date_from: null,
    sentry_date_to: null,
    unread_comments: 0,
    data: {
      weight: 44,
      floor: 3,
      elevator: '',
      in_bed: 1,
      subway: '',
    },
    pool: {},
    pools: [],
    er_call: {},
    organization: {},
    parent_request: '',
    region: 1,
    name: ''
  },
  form: {
    uuid: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    sex: 0,
    birthday: '',
    complaints: '',
    request_number: '',
    request_reason: '',
    type: 1,
    policy_number: '',
    contact_number: '1',
    age: 0,
    city: '',
    address: '',
    status: 0,
    scheduled_date: null,
    sentry_date_from: null,
    sentry_date_to: null,
    pools: [],
    pool: {},
    organization: {},
    er_call: {},
    unread_comments: 0,
    data: {
      weight: undefined,
      floor: undefined,
      elevator: '',
      in_bed: 0,
      subway: '',
    },
    parent_request: '',
    region: 0,
    name: ''
  },
  hospitalizationForm: {
    hospital_ids: []
  },
  requests: {
    applications: [],
    bidding: [],
    er_calls: [],
    hospitalization: []
  },
  searchData: {
    applications: [],
    er_calls: [],
    hospitalization: [],
    finished: [],
  },
  pool: {
    eta: 0,
    brigade_type: '',
    rejection_reason: '',
    request_brigade: [],
    connection: {}
  },
  pools: [],
  er_call: {
    request_uuid: '',
    dispatcher_uuid: '',
    status: 0,
  },
  messages: [],
  activities: [],
  validationFields: {
    request_reason: true,
    first_name: true,
    last_name: true,
    middle_name: true,
    city: true,
    type: true,
    policy_number: true,
    sex: true,
    weight: true,
    birthday: true,
    contact_number: true,
    address: true,
    age: true,
    subway: true,
    elevator: true,
    in_bed: true,
    floor: true,
    complaints: true,
    scheduled_date: true,
    sentry_date_from: true,
    sentry_date_to: true,
    name: true
  },
  formValid: true,
  activePanel: 'data'
};

const requests = createSlice({
  name: 'requests',
  initialState: initialState,
  reducers: {
    setRequestsList: (state, action: PayloadAction<RequestsList>) => {
      for (const key in action.payload) {
        action.payload[key].forEach(el => el.visible = true)
      }

      state.requests = action.payload
      return state
    },
    setRequestForm: (state) => {
      state.form = {...state.request, birthday: moment(state.request.birthday).format('DD/MM/YYYY')}
      return state
    },
    resetForm: (state) => {
      state.form = initialState.form
      state.er_call = initialState.er_call
      state.validationFields = initialState.validationFields
      return state
    },
    setRequest: (state, action: PayloadAction<FullRequest>) => {
      state.request = action.payload.request
      state.pools = action.payload.pools
      state.er_call = action.payload.er_call
      state.insurance_responsible = action.payload.insurance_responsible
      state.emergency_org = action.payload.emergency_org
      state.booking_offers = action.payload.booking_offers
      return state
    },
    updateCommentsCount: (state, action: PayloadAction<string>) => {
      if(state.requests.er_calls?.length) {
        const erCallIndex = state.requests?.er_calls?.findIndex(el => el.uuid === action.payload)
        if(erCallIndex !== -1) {
          state.requests.er_calls[erCallIndex].unread_comments++
          const elem = state.requests.er_calls[erCallIndex]
          state.requests.er_calls.splice(erCallIndex, 1)
          state.requests.er_calls.unshift(elem)
        }
      }

      if(state.requests?.hospitalization?.length) {
        const hospitalizationIndex = state.requests?.hospitalization?.findIndex(el => el.uuid === action.payload)
        if(hospitalizationIndex !== -1) {
          state.requests.hospitalization[hospitalizationIndex].unread_comments++
          const elem = state.requests.hospitalization[hospitalizationIndex]
          state.requests.hospitalization.splice(hospitalizationIndex, 1)
          state.requests.hospitalization.unshift(elem)
        }
      }

      state.request.unread_comments++

      return state
    },
    resetCommentsCount: (state, action: PayloadAction<string>) => {
      if(state.requests.er_calls?.length) {
        const erCallIndex = state.requests.er_calls.findIndex(el => el.uuid === action.payload)
        erCallIndex !== -1 ? state.requests.er_calls[erCallIndex].unread_comments = 0 : null
      }

      if(state.requests.hospitalization?.length) {
        const hospitalizationIndex = state.requests.hospitalization.findIndex(el => el.uuid === action.payload)
        hospitalizationIndex !== -1 ? state.requests.hospitalization[hospitalizationIndex].unread_comments = 0 : null
      }

      state.request.unread_comments = 0

      return state
    },
    updateListItem: (state, action: PayloadAction<{ request_uuid: string, column: string, data: { key: string, value: any } }>) => {
      const payload = action.payload
      const requestIndex = state.requests[payload.column].findIndex(el => el.uuid === action.payload.request_uuid)
      if(requestIndex !== -1) {
        state.requests[payload.column][requestIndex][payload.data.key] = payload.data.value
      }
     return state
    },
    setMessages: (state, action: PayloadAction<Array<Message>>) => {
      state.messages = action.payload
      return state
    },
    setActivities: (state, action: PayloadAction<Array<Activity>>) => {
      state.activities = action.payload
      return state
    },
    setErRequest: (state, action: PayloadAction<FullRequest>) => {
      state.request = action.payload.request
      state.pool = action.payload.pool
      state.er_call = action.payload.er_call
      state.insurance_org = action.payload.insurance_org
      state.emergency_responsible = action.payload.emergency_responsible
      state.booking_offers = action.payload.booking_offers
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: string }>) => {
      const data = action.payload
      if (data.key.includes('data')) {
        const key: any = /\[([^)]*)]/.exec(data.key)![1] // reg-ex to get property from string
        state.form.data[key] = data.value
      } else {
        state.form[data.key] = data.value
      }
      return state
    },
    handleHospitalizationFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.hospitalizationForm[data.key] = data.value
    },
    validateInputs: (state, action: PayloadAction<Array<ValidationData> | ValidationData>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    },
    setActivePanel: (state, action: PayloadAction<string>) => {
      state.activePanel = action.payload
      return state
    },
    filterRequestsByType: (state, action: PayloadAction<{ column: string, value: any }>) => {
      const data = action.payload
      state.requests[data.column].forEach(el => {
        if (typeof data.value !== 'boolean' && data.value !== el.type && data.value) {
          el.visible = false
        } else if (data.value === 0) {
          el.visible = true
        } else el.visible = !(typeof data.value === 'boolean' && data.value !== el.problematic);
      })

      return state
    },
    filterRequestsByStatus: (state, action: PayloadAction<{ column: string, value: any }>) => {
      const data = action.payload
      state.requests[data.column].forEach(el => {
        if (typeof data.value !== 'boolean' && data.value !== el.status && data.value) {
          el.visible = false
        } else if (data.value === 0) {
          el.visible = true
        } else el.visible = !(typeof data.value === 'boolean' && data.value !== el.problematic);
      })

      return state
    },
    filterRequestsByErStatus: (state, action: PayloadAction<{ column: string, value: any }>) => {
      const data = action.payload
      state.requests[data.column].forEach(el => {
        if (typeof data.value !== 'boolean' && data.value !== el.er_call.status && data.value) {
          el.visible = false
        } else if (data.value === 0) {
          el.visible = true
        } else el.visible = !(typeof data.value === 'boolean' && data.value !== el.problematic);
      })

      return state
    },
    setSearchData: (state, action: PayloadAction<SearchData>) => {
      state.searchData.applications = action.payload.applications
      state.searchData.er_calls = action.payload.er_calls
      state.searchData.hospitalization = action.payload.hospitalization

      return state
    },
    resetState: (state, action: PayloadAction<string>) => {
      state[action.payload] = initialState[action.payload]
    }
  }
});

export const {
  setRequestForm,
  resetForm,
  setRequest,
  updateCommentsCount,
  resetCommentsCount,
  updateListItem,
  setMessages,
  setActivities,
  setErRequest,
  setRequestsList,
  handleFormChange,
  handleHospitalizationFormChange,
  validateInputs,
  setActivePanel,
  filterRequestsByType,
  filterRequestsByStatus,
  filterRequestsByErStatus,
  setSearchData,
  resetState
} = requests.actions;

export const createRequest = (toggleForm: boolean = true) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const requests = getState().requests
    const today = moment();
    const birthday = changeDateFormat(requests.form.birthday)
    const data = pick(requests.form, ['name', 'birthday', 'city', 'address', 'complaints', 'type', 'region'])
    const request = {...data, age: today.diff(moment(birthday).format('YYYY'), 'years'), birthday: new Date(birthday), data: {}}

    if (requests.formValid) {
      try {
        const res = await api.request.post('', request)
        await dispatch(getInsuranceRequestsList())
        dispatch(setRequest(res))
        toggleForm && dispatch(toggleRequestForm())
        return true
      } catch (e: any) {
        console.log('err', e)
        e.code === 400 && dispatch(setData({data: true, prop: 'noConnectionModalOpen'}))
        dispatch(setApiError(e))
      }
    } else {
      dispatch(setData({data: true, prop: 'errorModalOpen'}))
    }
  }
};

export const editRequest = (request_uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const requests = getState().requests
    const {user} = getState().user
    const today = moment();
    const birthday = changeDateFormat(requests.form.birthday)
    const request = {...requests.form, age: today.diff(moment(birthday).format('YYYY'), 'years'), birthday: new Date(birthday)}
    if (requests.formValid) {
      try {
        const res = await api.request.put(`patient/${request_uuid}/${user.organization.type}`, request)

        if(user.organization.type === 2) {
          dispatch(setErRequest(res))
          dispatch(getErRequestsList())
        } else {
          dispatch(setRequest(res))
          dispatch(getInsuranceRequestsList())
        }
        dispatch(togglePopover('editRequestOpen'))
      } catch (e) {
        console.log('err', e)
        dispatch(setApiError(e))
      }
    } else {
      dispatch(setData({data: true, prop: 'errorModalOpen'}))
    }
  }
}

export const replaceRequest = (uuid) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const requests = getState().requests

    if (requests.formValid) {
      const data = pick(requests.form, ['name', 'birthday', 'city', 'address', 'complaints', 'type', 'region', 'age', 'notes'])
      try {
        const res = await api['er-call'].post(`transfer-to-request/${uuid}`, {...data, data: {}})
        dispatch(setRequest(res))
        dispatch(getInsuranceRequestsList())
        dispatch(toggleMainPanel())
        dispatch(toggleRequestForm())
        return true
      } catch (e) {
        console.log('err', e)
        dispatch(setApiError(e))
      }
    }
  }
}

export const getMessages = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.comments.get(request.uuid)
      dispatch(setMessages(res))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const sendMessage = (message: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.comments.post('comment', {request_uuid: request.uuid, message})
      dispatch(setMessages(res))
      return res
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const sendFile = (document: FormData) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.comments.upload(`document/${request.uuid}`, document)
      dispatch(setMessages(res.data))
      dispatch(updateCommentsCount(request.uuid))
      return res
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getFile = (fileName: string, fileOriginalName?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.comments.getFileByFullUrl(`document/${request.uuid}/${fileName}`)
      downloadFile(res, fileOriginalName || fileName)
      //TODO: some approve notification trigger
    } catch (e) {
      console.log("err", e)
      dispatch(setApiError(e))
    }
  }
}

export const markAsRead = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests

    try {
      const res = await api.comments.post('mark-as-read', {request_uuid: request.uuid})
      dispatch(setMessages(res))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getActivities = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request, er_call} = getState().requests
    try {
      const res = await api.activities.get(`${er_call.uuid}?request_uuid=${request.uuid}`)
      dispatch(setActivities(res))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const searchRequests = (query: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const {user} = getState().user
      const {activeRegion} = getState().app
      const region = activeRegion.id === 1 ? '' : activeRegion.id
      const res = await api.request.get('search', {query, region})
      if (user.organization?.type === 3) {
        res.er_calls = [...res.er_calls, ...res.hospitalization]
      }
      dispatch(setSearchData(res))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.request.get(`/avatar/${uuid}/${avatar}`)
      //dispatch(handleFormChange({key: 'avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const declineOffer = (token: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api['booking-request'].post('decline-booking', {token});
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const acceptBooking = (data: Booking) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api['booking-request'].post('accept-booking', data);
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getBookingOffer = (token: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api['booking-request'].get(`get-booking-info?token=${token}`);
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const checkPublicToken = (token: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api['booking-request'].post('check-public-token', {token})
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}


export default requests.reducer;
