import React, {Fragment} from 'react'
import {
  AmbulanceIcon,
  CanceledIcon,
  DashboardIcon, HistoryIcon, InProcessIcon,
  Logo,
  LogoSmall,
  PlusIcon,
  RequestsIcon,
  ShieldIcon
} from "../../assets";
import {Button} from "../../ui-components";
import {Link} from "react-router-dom";
import {NavbarTooltip} from "./navbar-tooltip";
import {useAppSelector} from "../../core/hooks";
import {ProblemTooltip} from "../../shared/tooltips";

const UserNavbar = ({user, app, pathname, onRequestFormToggle}) => {
  const {requests} = useAppSelector(state => state.requests)

  let allRequests: any = [];
  for (const key in requests) {
    for (const data of requests[key]) {
      allRequests.push(data)
    }
  }

  const problematicRequests = allRequests.filter(el => el.problematic === true)

  return (
    <Fragment>
      {user.organization.type === 3 ? <ShieldIcon className='type-icon'/> : <AmbulanceIcon className='type-icon'/>}
      {!app.navbarMinimized ? <Logo className='logo'/> : <LogoSmall className='logo'/>}
      {!app.navbarMinimized && <p className='org-name'>{user.organization.name}</p>}
      {user.organization.type === 3 &&
      <Button type='contained'
              color='secondary'
              onClick={() => onRequestFormToggle()}
              className={`create-request ${app.navbarMinimized && 'minimized'}`}
              startIcon={<PlusIcon/>}>{!app.navbarMinimized ? 'Создать Заявку' : ''}</Button>}
      <div className="divider"/>
      <ul className='list-open'>
        <Link className={`item ${pathname.includes('/dashboard') && 'active'}`} to='/dashboard'>
              <span data-tip data-for="nav" className='icon'>
                <DashboardIcon className='icon-dashboard'/>
              </span>
          {app.navbarMinimized && <NavbarTooltip id='nav' text='Главная'/>}
          {!app.navbarMinimized && <p>Главная</p>}
          {problematicRequests.length > 0 &&
          <ProblemTooltip className='problem-tooltip' dataFor={'problematic'}
                          text={`${problematicRequests.length} проблемы`}/>}
        </Link>
        {/*<Link className={`item ${pathname.includes('/scheduled') && 'active'}`} to='/scheduled'>*/}
        {/*      <span data-tip data-for="nav4" className='icon'>*/}
        {/*        <CalendarIcon className='icon-calendar'/>*/}
        {/*      </span>*/}
        {/*  {app.navbarMinimized && <NavbarTooltip id='nav4' text='Запланированные'/>}*/}
        {/*  {!app.navbarMinimized && <p>Запланированные</p>}*/}
        {/*</Link>*/}
        <Link className={`item ${pathname.includes('/pending') && 'active'}`} to='/pending'>
              <span data-tip data-for="nav1" className='icon'>
                <RequestsIcon className='icon-request'/>
              </span>
          {app.navbarMinimized && <NavbarTooltip id='nav1' text='Заявки'/>}
          {!app.navbarMinimized && <p>Заявки</p>}
        </Link>
        <Link className={`item ${pathname.includes('/in-process') && 'active'}`} to='/in-process'>
              <span data-tip data-for="nav2" className='icon'>
                <InProcessIcon className='icon-in-process'/>
              </span>
          {app.navbarMinimized && <NavbarTooltip id='nav2' text='В Исполнении'/>}
          {!app.navbarMinimized && <p>В Исполнении</p>}
        </Link>
        <Link className={`item ${pathname.includes('/history') && 'active'}`} to='/history'>
              <span data-tip data-for="nav3" className='icon'>
                <HistoryIcon className='icon-history'/>
              </span>
          {app.navbarMinimized && <NavbarTooltip id='nav5' text='История'/>}
          {!app.navbarMinimized && <p>История</p>}
        </Link>
        {user.organization.type !== 3 &&
          <Link className={`item ${pathname.includes('/rejected') && 'active'}`} to='/rejected'>
              <span data-tip data-for="nav5" className='icon'>
                <CanceledIcon className='icon-rejected'/>
              </span>
            {app.navbarMinimized && <NavbarTooltip id='nav5' text='Отказы'/>}
            {!app.navbarMinimized && <p>Отказы</p>}
          </Link>
        }
        {user.organization.type !== 2 &&
        <Link className={`item ${pathname.includes('/canceled') && 'active'}`} to='/canceled'>
              <span data-tip data-for="nav5" className='icon'>
                <CanceledIcon className='icon-rejected'/>
              </span>
          {app.navbarMinimized && <NavbarTooltip id='nav5' text='Отмененые'/>}
          {!app.navbarMinimized && <p>Отмененые</p>}
        </Link>
        }
      </ul>
    </Fragment>
  )

}

export default UserNavbar
