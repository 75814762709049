import React, {FC, ReactElement, useEffect} from 'react'
import "./card.scss"
import {
  FemaleIcon,
  MaleIcon,
  TimeIcon,
  CommentIcon,
  AcceptedIcon,
  OfferIcon,
  WaitingIcon,
  ArrivedIcon,
  HospitalIcon,
  OnWayIcon,
  ErrorIcon,
  AmbulanceIcon, LinkIcon,
} from "../../assets";
import {Request} from "../requests/IRequests";
import {Timer} from '../../shared';
import { CITIES_ABBR, ER_CALL_STATUSES, TIME_LIMIT } from '../../services/constants'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {buildOffersAmountMessage} from "../../services/utils";
import {getSingleRequest} from "../requests/insurance-thunk";
import { setActivePanel } from '../requests/requests-slice';

interface Props {
  data: Request
  column: string
  onClick: () => void
}

const Card: FC<Props> = (props) => {

  let Icon: ReactElement | null = null;
  let statusText: string = '';
  let genderIcon: ReactElement | null = null;
  let cardFooterClass: string = 'card-footer-basic'
  let timeLimit: number = 0;
  const {requestReasons, cardAvatars} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const {data} = props;

  let brigade: any

  if (data.er_call?.status >= 1) {
    brigade = data.pool[0].request_brigade.find(el => el.status === 2)
  }

  switch (data?.sex) {
    case 1:
      genderIcon = <MaleIcon className='mr-5'/>
      break;
    case 2:
      genderIcon = <FemaleIcon className='mr-5'/>
      break;
  }

  if (data.er_call) {
    switch (data.er_call.status) {
      case 1:
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Подтверждено'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.approved
        break
      case 2:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'В пути'
        cardFooterClass = 'card-footer-blue'
        timeLimit = brigade?.eta * 60000

        break
      case 3:
        Icon = <ErrorIcon className='mr-5'/>
        statusText = 'В пути'
        cardFooterClass = 'card-footer-orange'
        timeLimit = brigade?.eta * 60000 + brigade?.delay * 60000
        break
      case 4:
        Icon = <ArrivedIcon className='mr-5'/>
        statusText = 'Прибыл'
        cardFooterClass = 'card-footer-green'
        timeLimit = TIME_LIMIT.arrived
        break
      case 7:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Запрос госпитализации'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.hospitalizationRequest
        break
      case 8:
        Icon = <TimeIcon className='mr-5'/>
        statusText = 'Запрос бронирования'
        cardFooterClass = 'card-footer-blue'
        break
      case 9:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Бронирование подтверждено'
        cardFooterClass = 'card-footer-green'
        timeLimit = TIME_LIMIT.bookingApproved
        break
      case 10:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'На пути в больницу'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.onRouteToHospital
        break
      case 11:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Стацианирование'
        cardFooterClass = 'card-footer-grey'
        timeLimit = TIME_LIMIT.stationing
        break
      case 13:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Требуется замена СМП'
        cardFooterClass = 'card-footer-orange'
        timeLimit = TIME_LIMIT.erReplacementRequired
        break
      case 14:
        Icon = <OnWayIcon className='mr-5'/>
        statusText = 'Вызов завершен: Передан другой СМП'
        cardFooterClass = 'card-footer-grey'
        break
      case 16:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Предложение о бронировании'
        cardFooterClass = 'card-footer-green'
        break
      case 19:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена в процессе'
        cardFooterClass = 'card-footer-orange'
        break
      case 20:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена в пути'
        cardFooterClass = 'card-footer-orange'
        break
      case 21:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена прибыла'
        cardFooterClass = 'card-footer-orange'
        break
      case 22:
        Icon = <ErrorIcon className='mr-5'/>
        statusText = 'На пути в больницу'
        cardFooterClass = 'card-footer-orange'
        timeLimit = TIME_LIMIT.onRouteToHospital
        break
    }
  } else {
    const offers = data.pool.filter(el => el?.status === 2)
    let offersCount: number = 0
    for (const offer of offers) {
      offersCount += offer.request_brigade.length
    }
    switch (data?.status) {
      //TODO: complete all statuses scenarios
      case 1:
        timeLimit = TIME_LIMIT.pending
        break;
      case 2:
        timeLimit = TIME_LIMIT.inProcess
        Icon = <OfferIcon className='mr-5'/>
        statusText = `${offersCount} ${buildOffersAmountMessage(offersCount)}`
        cardFooterClass = 'card-footer-blue'
        break;
      case 3:
        timeLimit = TIME_LIMIT.waitingApproval
        Icon = <WaitingIcon className='mr-5'/>
        statusText = 'Ожидает подтверждения'
        cardFooterClass = ''
        break;
    }
  }

  const requestReason = requestReasons.find(val => val.id === data.request_reason)

  const avatarData = cardAvatars.find(el => el.organizationUuid === data.insurance_org.uuid)

  const city = CITIES_ABBR.find(el => el.id === data.region)

  let timerDate: string

  /*if (data.status <= 3) {
    timerDate = data.created_at as string
  } else if (data.er_call) {
    timerDate = data.er_call.updated_at
  } else {
    timerDate = data.updated_at as string
  }*/

  return (
    <div className='card-container' onClick={props.onClick}>
      <div className={`card-content ${data.status > 1 ? 'shadow' : ''}`}>
        <div className="d-flex space-between mb-10">
          <p className='patient-name'>{genderIcon}
            <p className='text' title={`${data.name}`}>
              {data.name}</p>
          </p>
          <Timer date={(brigade && brigade.delay && data.er_call && data.er_call.status === ER_CALL_STATUSES.onRouteDelay) ? data.updated_at : data.er_call ? data.er_call.updated_at : data.updated_at}
                 request_uuid={data.uuid}
                 column={props.column}
                 timeLimit={timeLimit}/>
        </div>
        <div className='mb-10'>
          <p className='text'>{requestReason?.name}</p>
          {data?.parent_request &&
          <p className="connected-request mb-10 my-10"
             onClick={(e) => {
               dispatch(getSingleRequest(data?.parent_request))
               e.stopPropagation()
             }}>
            <LinkIcon/>{`Связ. заявка № ${data?.parent_request_number}`}
          </p>}
        </div>
        <div className='content-footer'>
          <p className="city">{city?.value}</p>
          <span className='stick'/>
          {data.er_call && <div className="org-info">
            {avatarData ? <img className="logo" src={avatarData.avatar} alt=''/> :
              <span className='logo'/>}
            <span className="name">{data.emergency_org?.name}</span>
          </div>}
          {data.er_call && <div className="stick"/>}
          <p className='card-number'>№ {data.request_number}</p>
          <div className="stick"/>
          {data?.unread_comments > 0 &&
          <p className='comments' onClick={() => dispatch(setActivePanel('comment'))}>
            <CommentIcon/>{data.unread_comments}
          </p>}
        </div>
      </div>
      {data.status > 1 &&
      <div className={`card-footer ${cardFooterClass}`}>
        <p className="left">{Icon} {statusText}</p>
        <p className='right'>
          {data.er_call?.status === 2 || data.er_call?.status === 3 ?
            <p className="time">{brigade.eta} {brigade.delay ? `+ ${brigade.delay}` : ''} мин</p> : null}
          <span className='arrow'> {'>'} </span>
        </p>
      </div>}
    </div>
  )
}

export default React.memo(Card)
