import React, {ReactElement, useEffect, useState, Fragment, useContext} from 'react'
import {
  Table,
  TableHead,
  TableBody, PanelHeader, InfoText, PanelFooter, Panel, Modal, Search,
} from '../../../shared'
import { useAppDispatch, useAppSelector } from '../../../core/hooks'
import {
  getPartnerships,
  approveConnection,
  rejectConnection,
  cancelConnection
} from './emergency-partnership-slice'
import {
  EMERGENCY_PARTNERSHIPS,
  PARTNERSHIPS_PRIORITY_TEXTS,
  PARTNERSHIPS_STATUS_TEXTS
} from '../../../services/constants'
import PartnershipStatusText from '../insurance/partnership-status-text'
import {Autocomplete, Button} from '../../../ui-components'
import { setData, toggleMainPanel } from '../../../app/app-slice'
import { CloseIcon } from '../../../assets'
import styles from '../styles.module.scss'
import SocketContext from "../../../core/socket-context";
import {useHistory} from "react-router-dom";
import {filterData, updateFilter} from "../insurance/insurance-partnership-slice";
import {INSURANCE_CONNECTION_STATUSES} from "../constants";

const EmergencyPartnerships = () => {
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext);
  const {partnerships, filter} = useAppSelector(state => state.insurancePartnerships)
  const [selectedER, setSelectedER] = useState<any>({})
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const [connectionUUID, setConnectionUUID] = useState<string>('')
  const app = useAppSelector(state => state.app)
  const history = useHistory()
  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Сотрудничество'}))
    dispatch(getPartnerships())
  }, [dispatch, history.location])

  useEffect(() => {
    socket.on('connection', () => {
      dispatch(getPartnerships())
    })
  }, [socket])

  const setStatusText = (status): ReactElement => {
    let statusText:any = ''
    switch (status) {
      case 1:
        statusText = <Button type='contained'>Принять запрос</Button>
        break
      case 2:
        statusText = <PartnershipStatusText color={'#192233'} text={PARTNERSHIPS_STATUS_TEXTS[status - 1]}/>
        break
      case 3:
        statusText = <PartnershipStatusText color={'#192233'} text={PARTNERSHIPS_STATUS_TEXTS[status - 1]}/>
        break
      case 4:
        statusText = <PartnershipStatusText color={'#192233'} text={PARTNERSHIPS_STATUS_TEXTS[status - 1]}/>
        break
    }
    return statusText
  }

  const setPanelFooterBtn = (connection) => {
    switch (connection.statusNumber) {
      case 1:
        return (
          <Fragment>
          <Button className={'mr-10'} type='contained' fullWidth onClick={() => responseConnectionRequest(connection.uuid)}>Принять</Button>
          <Button className={'mr-10'} type='contained' fullWidth onClick={() => sendRejectConnection(connection.uuid)}>Отклонить</Button>
        </Fragment>
        )
      case 2:
        return <Button type='contained' fullWidth onClick={() => cancelConnectionModel(connection.uuid)}>Отменить партнерство</Button>
    }
  }

  const selectER = (er, index) => {
    er.statusNumber = statusArr[index].statusNumber
    er.uuid = statusArr[index].uuid
    setSelectedER(er)
    dispatch(toggleMainPanel())
  }

  const filtered = [] as any
  const statusArr = [] as any
  for (let partner of partnerships) {
    if(partner.visible) {
      filtered.push({
        name: partner.name,
        address: partner.address,
        phone: partner.phone,
        email: partner.email,
        status: setStatusText(partner.status)
      })
    }
    statusArr.push({
      statusNumber: partner.status,
      uuid: partner.uuid
    })
  }

  const cancelConnectionModel = (uuid: string) => {
    setCancelModalOpen(true)
    setConnectionUUID(uuid)
  }

  const responseConnectionRequest = (connection_uuid) => {
    dispatch(approveConnection({ uuid: connection_uuid }))
      .then(res => {
        if (res && res.uuid) {
          dispatch(getPartnerships())
          dispatch(toggleMainPanel())
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const sendRejectConnection = (uuid) => {
    dispatch(rejectConnection(uuid))
      .then(res => {
        if (res && res.uuid) {
          dispatch(getPartnerships())
          dispatch(toggleMainPanel())
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const sendCancelConnection = async () => {
    await dispatch(cancelConnection(connectionUUID))
    setCancelModalOpen(false)
    dispatch(getPartnerships())
    dispatch(toggleMainPanel())
  }

  const bodyRow = filtered.map((el, i) => Object.values(el))
  return (
    <div id={'partnerships'}>
      <div className={styles.filterHeader}>
        <Search value={filter.name as string} placeholder={'Поиск'} handleChange={(e) => {
          dispatch(updateFilter('name', e.target.value))
            .then(() => dispatch(filterData()))
        }}/>
        <div className='d-flex'>
          <Autocomplete label='Статус'
                        isClearable
                        onChange={(data) => {
                          dispatch(updateFilter('status', data.key))
                            .then(() => dispatch(filterData()))
                        }}
                        value={filter.status}
                        className={styles.selectWrapper}
                        options={INSURANCE_CONNECTION_STATUSES}
                        optionLabel={'value'} optionValue={'key'}/>
        </div>

      </div>
        <Table>
          <TableHead>
            {EMERGENCY_PARTNERSHIPS.map((el, i) => <div className={`item`} key={i}>{el}</div>)}
          </TableHead>
          <TableBody>
            {filtered.map((el, i) =>
              <div key={i} className='row'>
                {
                  bodyRow[i].map((el, index) =>
                    <div key={index} className={`item`} onClick={() => selectER(filtered[i], i)}>
                    <span title={el}>
                      {el}
                    </span>
                    </div>)
                }
              </div>
            )}
          </TableBody>
        </Table>
        <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
          <PanelHeader>
            <div id='header'>
              <div className={`request-info ${styles.requestInfo}`}>
                <div className={`title-wrapper ${styles.titleWrapper}`}>
                  <div className="status-wrapper">
                    <p className={`${styles.title}`}>СМП</p>
                  </div>
                  <div className="actions">
                    <CloseIcon onClick={() => dispatch(toggleMainPanel())}/>
                  </div>
                </div>
              </div>
            </div>
          </PanelHeader>
          <div id={`${styles.insurancePartnershipCard}`} className={`request-info-container`}>
            <p className={`title ${styles.orgName}`}>{selectedER.name}</p>
            <InfoText className='mb-20' title='Адрес' text={selectedER.address}/>
            <div className='wrapper flex-row d-flex space-between'>
              <InfoText title='Телефон' text={selectedER.phone}/>
              <InfoText title='Почта' text={selectedER.email}/>
            </div>
          </div>
          <PanelFooter className='d-flex space-between'>
            {setPanelFooterBtn(selectedER)}
            <Button className={'ml-15'} type='outlined' fullWidth onClick={() => dispatch(toggleMainPanel())}>Закрыть</Button>
          </PanelFooter>
        </Panel>
      <Modal open={cancelModalOpen}
             closeIcon
             title={'Отменить сотрудничество'}
             handleClose={() => {
               setCancelModalOpen(false)
             }}>
        <p className="modalText mb-50">Вы уверены, что хотите отменить сотрудничество?</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={sendCancelConnection}>Да</Button>
          <Button className={`ml-30`}
                  fullWidth
                  type='outlined'
                  onClick={() => setCancelModalOpen(false)}>Нет</Button>
        </div>
      </Modal>
    </div>
  )
}

export default EmergencyPartnerships
