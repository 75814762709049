import React, {Fragment, useContext, useEffect, useState} from 'react';
import {pick} from 'lodash'
import {Redirect, Route, Router, Switch, useHistory, useLocation} from 'react-router-dom';
import {withSocketInstance} from '../hoc/socket-hoc'
import Header from '../shell/header/header';
import Navbar from '../shell/navbar/navbar';
import InsuranceDashboard from "../modules/insurance-dashboard/insurance-dashboard";
import ErDashboard from "../modules/er-dashboard/er-dashboard";
import {ReactComponent as Logo} from "../assets/svgs/header/logo.svg";
import { Activation, ForgotPassword, Login, ResetPassword, BookingDecline, BookingAccept } from '../modules/auth-forms'
import {getCitiesList, getHospitalsList, getRequestReasonsList, setData, toggleRequestForm, setApiError} from "./app-slice";
import RequestForm from "../modules/requests/request-form";
import {Modal, Panel, PanelFooter, PanelHeader, PrimaryModal, Toaster} from "../shared";
import {useAppDispatch, useAppSelector} from "../core/hooks";
import {CloseIcon} from '../assets';
import {isEmpty} from 'lodash'
import {Button} from "../ui-components";
import {createRequest, replaceRequest, validateInputs} from "../modules/requests/requests-slice";
import ErActiveTickets from '../modules/left-panel/active-tickets/emergency/er-active-tickets'
import InsuranceActiveTickets from '../modules/left-panel/active-tickets/insurance/insurance-active-tickets'
import ErPendingTickets from '../modules/left-panel/pending-tickets/emergency/er-pending-tickets'
import InsurancePendingTickets from '../modules/left-panel/pending-tickets/insurance/insurance-pending-tickets'
import InsuranceHistory from "../modules/left-panel/history/insurance-history/insurance-history"
import ErHistory from "../modules/left-panel/history/er-history/er-history";
import ErRejectedOffers from "../modules/left-panel/er-rejects/er-rejects";
import InsuranceCanceled from '../modules/left-panel/insurance-canceled/insurance-canceled'
import DispatchersList from '../modules/dispatchers/dispatchers-list';
import InsurancePartnershipsList from '../modules/partnership/insurance/insurance-partnership'
import EmergencyPartnershipsList from '../modules/partnership/emergency/emergency-partnership'
import {getNotifications} from "../modules/notifications/notifications-slice";
import NotificationSoundManager from "../modules/notification-sound-manager/notification-sound-manager";
import SocketContext from "../core/socket-context";
import './app.scss'

import {checkToken, getUserAvatar, logout} from "../modules/user/user-slice";
import { getSingleRequest } from '../modules/requests/insurance-thunk'

function App() {
  const {user} = useAppSelector(state => state.user)
  const socket = useContext(SocketContext);
  const [background, setBackground] = useState<string>('')
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)
  const [booking, setBooking] = useState<string>('')

  const history = useHistory()
  const dispatch = useAppDispatch()
  const app = useAppSelector(state => state.app)
  const {form, er_call} = useAppSelector(state => state.requests)
  const {pathname} = useLocation();
  const token = localStorage.getItem('token')
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const [noPoolModelOpen, setNoPoolModelOpen] = useState<boolean>(false)

  // todo need to rework this part
  // if(queryToken && !isEmpty(user)) {
  //   localStorage.removeItem('token')
  //   localStorage.removeItem('user')
  //   window.location.href=(`${pathname}?=${queryToken}`)
  // }

  const isAuthPathname = () => {
    return pathname.includes('activation') || pathname.includes('forgot-password') || pathname.includes('login') || pathname.includes('reset-password') || pathname.includes('booking-reserve') || pathname.includes('booking-reserve-decline')
  }

  if ((!token || isEmpty(user)) && !isAuthPathname()) {
    history.push('/login')
  } else if (isAuthPathname() && !isEmpty(user) && token) {
    history.push('/dashboard')
  }

  useEffect(() => {
    if (pathname.includes('activation') || pathname.includes('forgot-password')) {
      setBackground('er-background')
    } else if (pathname.includes('login')) {
      setBackground('nurse-background')
    } else if (pathname.includes('reset-password')) {
      setBackground('car-background')
    } else if (pathname.includes('booking-reserve') || pathname.includes('booking-reserve-decline')) {
      setBackground('er-background')
      setBooking('booking')
    }
    if (!isEmpty(user)) {
      dispatch(checkToken(token as string))
        .then(res => {
          if(res?.code === 403) {
            dispatch(logout())
          }
          if(user.avatar && !user.avatar.includes('data:image/jpeg;base64')) {
            dispatch(getUserAvatar())
          }
        })
      socket.connect()

      dispatch(getNotifications())
      !app.requestReasons.length && dispatch(getRequestReasonsList())
      !app.cities.length && dispatch(getCitiesList())
        .then(res => {
          if (res) {
            if(user.organization.cities?.some(el => el.city === 1)) {
              dispatch(setData({data: res[0], prop: 'activeRegion'}))
            } else {
              const filteredCities = res.filter(el => user.organization.cities?.find(data => el.id === data.city))
              dispatch(setData({data: filteredCities[0], prop: 'activeRegion'}))
            }
          }
        })
    }
    if (app.apiError.status && app.apiError.text) {
      setErrorModalOpen(true)
    }
  }, [app.apiError.status, app.apiError.text, pathname])

  // submit request form
  const submitForm = () => {
    setClickDisabled(true)
    const mainValidationData = pick(form, ['name', 'birthday', 'city', 'address', 'complaints', 'type', 'region'])

    for (const k in mainValidationData) {
      if (typeof form[k] === 'object') {
        for (const j in form[k]) {
          dispatch(validateInputs({
            key: j,
            value: form.data[j]
          }))
        }
      }

      dispatch(validateInputs({
        key: k,
        value: form[k]
      }))
    }

    if (form.type === 2) {
      dispatch(validateInputs({
        key: 'scheduled_date',
        value: form.scheduled_date
      }))
    }

    if (form.type === 3) {
      dispatch(validateInputs([
        {
          key: 'sentry_date_from',
          value: form.sentry_date_from
        },
        {
          key: 'sentry_date_to',
          value: form.sentry_date_to
        }
      ]))
    }
    if (er_call?.status === 13) {

      dispatch(replaceRequest(er_call.uuid))
        .then(() => setClickDisabled(false))
    } else {
      dispatch(createRequest())
        .then(res => {
          setClickDisabled(false)
        })
    }
  }

  const closeErrorModal = () => {
    setErrorModalOpen(false)
    dispatch(setApiError({}))
  }

  let Dashboard: any;
  let Requests: any;
  let History: any;
  let Rejected: any;
  let Canceled: any;
  let ActiveTickets: any;
  let PendingTickets: any;
  let PartnershipsList: any;
  const favicon = document.getElementById("favicon") as HTMLLinkElement;

  if (user.organization?.type === 3) {
    document.title = 'СК Тэмп 03'
    favicon.href = 'insurance-fav-310x310.png'
    Dashboard = <InsuranceDashboard/>
    PendingTickets = <InsurancePendingTickets/>
    History = <InsuranceHistory/>
    Canceled = <InsuranceCanceled/>
    ActiveTickets = <InsuranceActiveTickets/>
    PartnershipsList = <InsurancePartnershipsList/>
  } else {
    document.title = 'СМП Тэмп 03'
    favicon.href = 'emergency-fav-310x310.png'
    Dashboard = <ErDashboard/>
    History = <ErHistory/>
    Rejected = <ErRejectedOffers/>
    ActiveTickets = <ErActiveTickets/>
    PendingTickets = <ErPendingTickets/>
    PartnershipsList = <EmergencyPartnershipsList/>
  }

  return (
    <Fragment>
      {!isEmpty(user) ? <div className='content-wrapper'>
          <Navbar type={'user'}/>
          <div className={`container ${app.navbarMinimized ? 'navbar-minimized' : ''}`}>
            <Header type={'app'} history={history}/>
            <section className='data-section'>
              <Router history={history}>
                <Switch>
                  <Route exact path="/">
                    <Redirect to='dashboard'/>
                  </Route>
                  <Route path="/dashboard">

                    {app.activeRegion?.id && Dashboard}
                  </Route>
                  <Route path="/requests">
                    {Requests}
                  </Route>
                  <Route path="/history">
                    {History}
                  </Route>
                  <Route path="/rejected">
                    {Rejected}
                  </Route>
                  <Route path="/in-process">
                    {ActiveTickets}
                  </Route>
                  <Route path="/pending">
                    {PendingTickets}
                  </Route>
                  <Route path="/canceled">
                    {Canceled}
                  </Route>
                  <Route path="/dispatchers" component={DispatchersList}/>
                  <Route path="/partnership">{app.activeRegion?.id && PartnershipsList}</Route>
                </Switch>
              </Router>
            </section>
            <NotificationSoundManager />
          </div>
          <Panel handleClose={() => dispatch(toggleRequestForm())}
                 open={app.requestFormOpen}>
            <PanelHeader className='simple-header'>
              <p>Создать заявку</p>
              <CloseIcon onClick={() => dispatch(toggleRequestForm())}/>
            </PanelHeader>
            <RequestForm/>
            <PanelFooter className='action-box'>
              <Button type='simple' onClick={() => dispatch(toggleRequestForm())}>Отмена</Button>
              <Button type='contained' onClick={submitForm}
                      className={`ml-20 ${clickDisabled ? 'click-disabled' : ''}`}>Создать</Button>
            </PanelFooter>
          </Panel>
          <Modal open={app.errorModalOpen}
                 closeIcon
                 className='close-request-modal'
                 handleClose={() => dispatch(setData({
                   data: false,
                   prop: 'errorModalOpen'
                 }))}>
            <p className="text mb-40">Пожалуйста, заполните все поля.</p>
            <div className='d-flex space-between'>
              <Button type='contained'
                      onClick={() => dispatch(setData({
                        data: false,
                        prop: 'errorModalOpen'
                      }))}>Закрыть</Button>
            </div>
          </Modal>
        </div> :
        <div className={`sign-in-wrapper ${booking}`}>
          <div className="forms-container">
            <Logo className='logo'/>
            <Router history={history}>
              <Switch>
                <Route exact path="/">
                  <Redirect to={`login`}/>
                </Route>
                <Route path="/login" component={Login}/>
                <Route path="/activation" component={Activation}/>
                <Route path="/forgot-password" component={ForgotPassword}/>
                <Route path="/reset-password" component={ResetPassword}/>
                <Route path="/booking-reserve" component={BookingAccept}/>
                <Route path="/booking-reserve-decline" component={BookingDecline}/>
              </Switch>
            </Router>
          </div>
          <div className='image-container'>
            <div className={`background ${background}`}/>
          </div>
        </div>}
      <PrimaryModal
        description={'Чтобы добавить исполнителей СМП войдите в свой аккаунт, из пунктов меню кликните на “Сотрудничество” и отправьте приглашение на сотрудничество или нажмите на кнопку “Добавить”'}
        text={'У вас нет сотрудничающих исполнителей СМП '}
        open={app.noConnectionModalOpen}
        footer={<Button onClick={() => {
          history.push('/partnership')
          dispatch(setData({data: false, prop: 'noConnectionModalOpen'}))
        }} fullWidth type='contained'>Добавить</Button>}
        handleClose={() => dispatch(setData({data: false, prop: 'noConnectionModalOpen'}))}/>
      <Modal open={errorModalOpen}
             closeIcon
             title={'Ошибка'}
             handleClose={() => {
               closeErrorModal()
             }}>
        <p className="modalText mb-50">{app.apiError.text}</p>
        <div className='d-flex space-between'>
          <Button type='contained'
                  fullWidth
                  onClick={closeErrorModal}>Закрыть</Button>
        </div>
      </Modal>
      <PrimaryModal
        description={'Единственный партнер отменил все свои предложения для вашей заявки. Заявка будет закрыта.'}
        text={'Заявка осталась без предложений'}
        open={app.noPoolModelOpen}
        footer={<Button onClick={() => {
          dispatch(getSingleRequest(app.noPoolRequest.uuid))
          // history.push('/history')
          dispatch(setData({data: false, prop: 'noPoolModelOpen'}))
        }} fullWidth type='contained'>Открыть в истории</Button>}
        handleClose={() => dispatch(setData({data: false, prop: 'noPoolModelOpen'}))}/>
      <div id='modal-root'/>
      <div id="panel-root"/>
      {app.toaster.visible && <Toaster/>}
    </Fragment>
  )
}

export default React.memo(withSocketInstance(App));
