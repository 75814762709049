import {User} from '../modules/user/IUsers'

export const USER: User = JSON.parse(localStorage.getItem('user') || '{}')

export const brigadeTypes = [
  {
    key: 1,
    value: 'Реанимационная'
  },
  {
    key: 2,
    value: 'Фельдшерская'
  },
  {
    key: 3,
    value: 'Линейная'
  },
  {
    key: 4,
    value: 'Педиатрическая'
  }
]

export const diagnoses = [
  {
    key: 1,
    value: 'COVID'
  },
  {
    key: 2,
    value: 'Инфаркт'
  },
  {
    key: 3,
    value: 'Инсульт'
  },
  {
    key: 4,
    value: 'Хороший был человек'
  }
]

export const REQUEST_STATUSES = {
  pending: 1,
  inProcess: 2,
  selected: 3,
  closed: 4,
  canceled: 5,
  deleted: 6
}

export const ER_CALL_STATUSES = {
  onApproval: 1,
  onRoute: 2,
  onRouteDelay: 3,
  arrived: 4,
  endedLeftInPlace: 5,
  endedRefusedHospital: 6,
  hospitalizationRequest: 7,
  bookingRequestSent: 8,
  bookingApproved: 9,
  onRouteToHospital: 10,
  stationing: 11,
  ended: 12,
  erReplacementRequired: 13,
  endedTransferred: 14,
  closed: 15,
  bookingOfferReceived: 16,
  closedWithPayment: 17,
  closedWOPayment: 18,
  transferInProcess: 19,
  transferOnRoute: 20,
  transferArrived: 21,
  onRouteToHospitalDelay: 22
}

export const STATUS_LIST = [
  {
    key: ER_CALL_STATUSES.onRoute,
    value: 'В пути',
  },
  {
    key: ER_CALL_STATUSES.arrived,
    value: 'Прибыл',
  },
  {
    key: ER_CALL_STATUSES.endedLeftInPlace,
    value: 'Вызов завершен: На месте',
  },
  {
    key: ER_CALL_STATUSES.endedRefusedHospital,
    value: 'Вызов завершен: Oтказ',
  },
  {
    key: ER_CALL_STATUSES.hospitalizationRequest,
    value: 'Запрос госпитализации',
  },
  {
    key: ER_CALL_STATUSES.onRouteToHospital,
    value: 'На пути в больницу',
  },
  {
    key: ER_CALL_STATUSES.stationing,
    value: 'Стационирование',
  },
  {
    key: ER_CALL_STATUSES.ended,
    value: 'Вызов завершен: Госпитализация',
  }
]

export const USER_ROLES = [
  {
    key: 2,
    value: 'Администратор'
  }, {
    key: 3,
    value: 'Диспетчер'
  }
]

export const TIME_LIMIT = {
  pending: 180000,
  inProcess: 60000,
  waitingApproval: 30000,
  approved: 60000,
  arrived: 1800000,
  hospitalizationRequest: 900000,
  bookingApproved: 60000,
  bookingRejected: 30000,
  stationing: 1800000,
  onRouteToHospital: 1800000,
  erReplacementRequired: 60000,
}

export const APPLICATIONS_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Новые',
    value: 1
  },
  {
    text: 'С предложениями',
    value: 2
  },
  {
    text: 'Ожидает подтверждения',
    value: 3
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const INSURANCE_ER_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Подтверждено',
    value: 1
  },
  {
    text: 'В пути',
    value: 2
  },
  {
    text: 'Прибыл',
    value: 4
  },
  {
    text: 'Требуется замена СМП',
    value: 13
  },
  {
    text: 'Замена в пути',
    value: 14
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const ER_BIDDING_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Новые',
    value: 1
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const HOSPITALIZATION_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Запрос госпитализации',
    value: 7
  },
  {
    text: 'Бронирование отклонено',
    value: 8
  },
  {
    text: 'Бронирование подтверждено',
    value: 9
  },
  {
    text: 'На пути в больницу',
    value: 10
  },
  {
    text: 'Стацианирование',
    value: 11
  },
  {
    text: 'Требуется замена СМП',
    value: 13
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const EMERGENCY_APP_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Отправленные предложения',
    value: 2
  },
  {
    text: 'Ожидает подверждения',
    value: 3
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const EMERGENCY_ER_FILTER = [
  {
    text: 'Все',
    value: 0
  },
  {
    text: 'Подтверждено',
    value: 1
  },
  {
    text: 'В пути',
    value: 2
  },
  {
    text: 'В пути(Задержка)',
    value: 3
  },
  {
    text: 'Прибыл',
    value: 4
  },
  {
    text: 'Запрос госпитализации',
    value: 7
  },
  {
    text: 'Бронирование подтверждено',
    value: 9
  },
  {
    text: 'На пути в больницу',
    value: 10
  },
  {
    text: 'Стацианирование',
    value: 11
  },
  {
    text: 'Требуется замена СМП',
    value: 13
  },
  {
    text: 'Проблематичные',
    value: true
  }
]

export const NOTIFICATIONS_FILTER = [
  {
    text: 'Все',
    type: 'all'
  },
  {
    text: 'Непрочитанные',
    type: 'unread'
  },
  {
    text: 'Прочитанные',
    type: 'read'
  }
]

export const GENDER = [{key: 1, type: 'М'}, {key: 2, type: 'Ж'}]

export const SEARCH_HEAD = ['Номер', 'Адрес', 'Повод обращения', 'Возраст', 'Дата', 'Имя пациента', 'Статус']

export const DISPATCHERS_TABLE_HEAD = ['ФИО', 'Дата', 'Телефон', 'Доп. Телефон', 'Моб. Телефон', 'Почта', '']

export const ACTIVE_TICKETS_STATUS_NAMES = [
  'Подтверждено',
  'В пути',
  'В пути (задержка)',
  'Прибыл',
  'Вызов завершен: Оставлен на месте',
  'Вызов завершен: Отказ от госпитализации',
  'Запрос госпитализации',
  'Запрос бронирования',
  'Бронирование подтверждено',
  'На пути в больницу',
  'Стационирование',
  'Вызов завершен',
  'Требуется замена СМП',
  'Вызов завершен: Передана другой СМП',
  'Вызов отменен',
  'Предложение о бронировании',
  'Вызов отменен, оплатить',
  'Вызов отменен, без оплаты',
  'Замена в процессе',
  'Замена в пути',
  'Замена прибыла'
]

export const REGIONS = [
  {key: 2, type: 'Москва'},
  {key: 2, type: 'За МКАД'},
  {key: 3, type: 'Санкт Петербург'},
  {key: 3, type: 'За КАД'},
]

export const PAYMENT_TYPES = [
  {key: 1, type: 'ОМС'},
  {key: 2, type: 'ДМС'}
]

export const ROOM_TYPES = [
  {key: 1, type: 'ВИП'},
  {key: 2, type: 'Одноместная'},
  {key: 3, type: 'Двухместная'},
  {key: 4, type: 'Общая'}
]

export const DEPARTMENTS = [
  {key: 1, type: 'акушерское дело'},
  {key: 2, type: 'акушерство и гинекология'},
  {key: 3, type: 'аллергология и иммунология'},
  {key: 4, type: 'анестезиология и реаниматология'},
  {key: 5, type: 'гастроэнтерология'},
  {key: 6, type: 'гематология'},
  {key: 7, type: 'гериатрия'},
  {key: 8, type: 'дерматовенерология'},
  {key: 9, type: 'детская кардиология'},
  {key: 10, type: 'детская онкология'},
  {key: 11, type: 'детская урология-андрология'},
  {key: 12, type: 'детская хирургия'},
  {key: 13, type: 'детская эндокринология'},
  {key: 14, type: 'инфекционные болезни'},
  {key: 15, type: 'кардиология'},
  {key: 16, type: 'колопроктология'},
  {key: 17, type: 'медицинская реабилитация'},
  {key: 18, type: 'неврология'},
  {key: 19, type: 'нейрохирургия'},
  {key: 20, type: 'неонатология'},
  {key: 21, type: 'нефрология'},
  {key: 22, type: 'онкология'},
  {key: 23, type: 'оториноларингология'},
  {key: 24, type: 'офтальмология'},
  {key: 25, type: 'паллиативная медицинская помощь'},
  {key: 26, type: 'педиатрия'},
  {key: 27, type: 'пластическая хирургия'},
  {key: 28, type: 'профпатология'},
  {key: 29, type: 'психиатрия'},
  {key: 30, type: 'психиатрия-наркология'},
  {key: 31, type: 'пульмонология'},
  {key: 32, type: 'радиология, радиотерапия'},
  {key: 33, type: 'ревматология'},
  {key: 34, type: 'сердечно-сосудистая хирургия'},
  {key: 35, type: 'скорая медицинская помощь'},
  {key: 36, type: 'стоматология детская'},
  {key: 37, type: 'терапия'},
  {key: 38, type: 'токсикология'},
  {key: 39, type: 'торакальная хирургия'},
  {key: 40, type: 'травматология и ортопедия'},
  {key: 41, type: 'трансплантация костного мозга и гемопоэтических стволовых клеток'},
  {key: 42, type: 'урология'},
  {key: 43, type: 'фтизиатрия'},
  {key: 44, type: 'хирургия'},
  {key: 45, type: 'хирургия (абдоминальная)'},
  {key: 46, type: 'хирургия (комбустиология)'},
  {key: 47, type: 'хирургия (трансплантация органов и (или) тканей)'},
  {key: 48, type: 'челюстно-лицевая хирургия'},
  {key: 49, type: 'эндокринология'}
]

export const ROOT_ADMIN_HOSPITAL_REGIONS = [
  {key: 1, type: 'ВАО'},
  {key: 2, type: 'ЗАО'},
  {key: 3, type: 'ЗЕЛ'},
  {key: 4, type: 'МО'},
  {key: 5, type: 'САО'},
  {key: 6, type: 'СВАО'},
  {key: 7, type: 'СВО'},
  {key: 8, type: 'СЗАО'},
  {key: 9, type: 'ЦАО'},
  {key: 10, type: 'ЮАО'},
  {key: 11, type: 'ЮВАО'},
  {key: 12, type: 'ЮЗАО'}
]

export const INSURANCE_PARTNERSHIPS = ['Название', 'Адрес', 'Телефон', 'Почта', 'Приоритет', ' ']

export const EMERGENCY_PARTNERSHIPS = ['Название', 'Адрес', 'Телефон', 'Почта', ' ']

export const PARTNERSHIPS_STATUS_TEXTS = ['Запрос отправлен', 'Партнер', 'Отклонено', 'Приостановлено']

export const PARTNERSHIPS_PRIORITY_TEXTS = [
  {key: 1, type: 'Высокий'},
  {key: 2, type: 'Средний'},
  {key: 3, type: 'Низкий'}
]

export const INSURANCE_CONN_STATUS_MESSAGES = {
  2: '[XXX] приняла Ваше предложение на сотрудничество.',
  3: '[XXX] отклонила Ваше предложение на сотрудничество.',
  5: '[XXX] отменила Ваше сотрудничество.'
}

export const ER_CONN_STATUS_MESSAGES = {
  1: '[XXX] отправила запрос на сотрудничество.',
  2: 'Ваше сотрудничество с [XXX] было возобновлено.',
  3: '[XXX] отменила Ваше сотрудничество.',
  4: 'Ваше сотрудничество с [XXX] было приостановлено.',
  5: '[XXX] отменила Ваше сотрудничество.'
}

export const CITIES = [{key: 1, value: 'Все'}, {key: 2, value: 'Москва'}, {key: 3, value: 'Питер'}]

export const CITIES_ABBR = [{id: 2, value: 'МСК'}, {id: 3, value: 'СПБ'}]
