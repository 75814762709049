import React, {FC} from 'react'
import './notification.scss'
import {useHistory} from 'react-router-dom';
import {OfferIcon} from "../../assets";
import {setDate} from '../../services/utils';
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {getSingleRequest} from "../../modules/requests/insurance-thunk";
import {getErSingleRequest} from "../../modules/requests/er-thunk";
import {markAsRead} from "../../modules/notifications/notifications-slice";

interface Props {
  request_uuid: string
  notification_uuid: string
  message: string
  patientName: string
  read: Array<number>
  date: string
  requestNumber: string,
  data: any
}

const Notification: FC<Props> = (props) => {
  const {user} = useAppSelector(state => state.user)
  const dispatch = useAppDispatch();
  const isRead = props.read?.includes(user.id)
  const history = useHistory()
  return (
    <div className={`notification ${!isRead ? 'new' : ''}`}
         onClick={() => {
           props.data?.connection ? history.push('/partnership') :
           (props.data?.isRejected ? history.push('/rejected') : (user.organization?.type === 3 ? dispatch(getSingleRequest(props.request_uuid)) : dispatch(getErSingleRequest(props.request_uuid))))
           dispatch(markAsRead(props.notification_uuid))
         }}>
      <OfferIcon className='icon icon-offer'/>
      <div className="content">
        <p className="content-title">{props.message}</p>
        <div className="wrapper">
          {!props.data?.connection && <div className="data">
            <p className="title">Номер</p>
            <p className="text">№ {props.requestNumber}</p>
          </div>
          }
          {!props.data?.connection && <div className="data">
            <p className="title">ФИО пациента</p>
            <p className="text">{props.patientName}</p>
            </div>
          }
          <p className="time">{setDate(props.date)}</p>
        </div>
      </div>
    </div>
  )
}

export default Notification
