import React, {FC, ReactElement} from 'react'
import {Timer} from '../../shared'
import "./card.scss"
import {
  FemaleIcon,
  MaleIcon,
  CommentIcon,
  AcceptedIcon,
  OfferIcon,
  ErOfferIcon,
  WaitingIcon,
  ArrivedIcon,
  HospitalIcon,
  OnWayIcon,
  ErrorIcon,
  AmbulanceIcon, TimeIcon, LinkIcon
} from "../../assets";
import {Request, Pool} from "../requests/IRequests";
import { CITIES_ABBR, ER_CALL_STATUSES, TIME_LIMIT } from '../../services/constants'
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {buildOffersAmountMessage} from "../../services/utils";
import {setActivePanel} from "../requests/requests-slice";
import card from "../insurance-dashboard/card";


interface Props {
  data: Request
  onClick: () => void
  column: string
}

const Card: FC<Props> = (props) => {

  let Icon: ReactElement | null = null;
  let statusText: string = '';
  let genderIcon: ReactElement | null = null;
  let cardFooterClass: string = 'card-footer-basic'
  let timeLimit: number = 0

  const {requestReasons, cardAvatars} = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const {data} = props;
  //todo pass data.created_at when we get real data

  const brigade = data.pool.request_brigade?.find(el => el.status === 2)

  const city = CITIES_ABBR.find(el => el.id === data.region)

  switch (data?.sex) {
    case 1:
      genderIcon = <MaleIcon className='mr-5'/>
      break;
    case 2:
      genderIcon = <FemaleIcon className='mr-5'/>
      break;
  }

  switch (data?.status) {
    //TODO: complete all statuses scenarios
    case 1:
      timeLimit = TIME_LIMIT.pending
      break;
    case 2:
      timeLimit = TIME_LIMIT.inProcess
      break;
    case 3:
      timeLimit = TIME_LIMIT.waitingApproval
      break;
  }

  if (data.er_call) {
    switch (data.er_call.status) {
      case 1:
        Icon = <AcceptedIcon className='mr-5'/>
        statusText = 'Подтверждено'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.approved
        break;
      case 2:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'В пути'
        cardFooterClass = 'card-footer-blue'
        timeLimit = brigade?.eta * 60000
        break;
      case 3:
        Icon = <ErrorIcon className='mr-5'/>
        statusText = 'В пути'
        cardFooterClass = 'card-footer-orange'
        timeLimit = brigade?.eta * 60000 + brigade?.delay * 60000
        break;
      case 4:
        Icon = <ArrivedIcon className='mr-5'/>
        statusText = 'Прибыл'
        cardFooterClass = 'card-footer-green'
        timeLimit = TIME_LIMIT.arrived
        break;
      case 7:
        Icon = <TimeIcon className='mr-5'/>
        statusText = 'Ожидает подтверждения'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.hospitalizationRequest
        break
      case 8:
        Icon = <TimeIcon className='mr-5'/>
        statusText = 'Ожидает подтверждения'
        cardFooterClass = 'card-footer-blue'
        //timeLimit = TIME_LIMIT.hospitalizationRequest
        break
      case 16:
        Icon = <TimeIcon className='mr-5'/>
        statusText = 'Ожидает подтверждения'
        cardFooterClass = 'card-footer-blue'
        break
      case 9:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Бронирование подтверждено'
        cardFooterClass = 'card-footer-green'
        timeLimit = TIME_LIMIT.hospitalizationRequest
        break
      case 10:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'На пути в больницу'
        cardFooterClass = 'card-footer-blue'
        timeLimit = TIME_LIMIT.onRouteToHospital
        break
      case 11:
        Icon = <HospitalIcon className='mr-5'/>
        statusText = 'Стацианирование'
        cardFooterClass = 'card-footer-grey'
        timeLimit = TIME_LIMIT.stationing
        break
      case 13:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Требуется замена СМП'
        cardFooterClass = 'card-footer-orange'
        timeLimit = TIME_LIMIT.erReplacementRequired
        break
      case 14:
        Icon = <OnWayIcon className='mr-5'/>
        statusText = 'Вызов завершен: Передан другой СМП'
        cardFooterClass = 'card-footer-grey'
        break
      case 19:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена в процессе'
        cardFooterClass = 'card-footer-orange'
        timeLimit = 0
        break
      case 20:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена в пути'
        cardFooterClass = 'card-footer-orange'
        break
      case 21:
        Icon = <AmbulanceIcon className='mr-5'/>
        statusText = 'Замена прибыла'
        cardFooterClass = 'card-footer-orange'
        break
      case 22:
        Icon = <ErrorIcon className='mr-5'/>
        statusText = 'На пути в больницу'
        timeLimit = TIME_LIMIT.onRouteToHospital
        cardFooterClass = 'card-footer-orange'
        break
    }
  } else {
    switch (data.pool?.status) {
      case 2:
        if (data.pool.approved_by) {
          Icon = <WaitingIcon className='mr-5'/>
          statusText = 'Ожидает подтверждения'
          cardFooterClass = 'card-footer-blue'
        } else {
          Icon = <ErOfferIcon className='mr-5'/>
          statusText = `${data.pool.request_brigade.length} ${buildOffersAmountMessage(data.pool.request_brigade.length)}`
        }
        break;
      case 4:
        Icon = <AcceptedIcon className='mr-5'/>
        break;
      case 5:
        Icon = <OfferIcon className='mr-5'/>
        break;
      case 6:
        Icon = <ErrorIcon className='mr-5'/>
    }
  }

  const requestReason = requestReasons.find(val => val.id === data.request_reason)

  const avatarData = cardAvatars.find(el => el.organizationUuid === data.organization.uuid)

  return (
    <div className='card-container' onClick={props.onClick}>
      <div className={`card-content ${data.pool.status > 1 ? 'shadow' : ''}`}>
        <div className="d-flex space-between mb-10">
          <p className='patient-name'>
            {genderIcon}
            <p className='text' title={`${data.name}`}>
              {data.name}</p>
          </p>
          <Timer date={(brigade && brigade.delay && data.er_call && data.er_call.status === ER_CALL_STATUSES.onRouteDelay) ? data.updated_at : data.er_call ? data.er_call.updated_at : data.updated_at}
                 request_uuid={data.uuid}
                 column={props.column}
                 timeLimit={timeLimit}/>
        </div>
        {/*<div className='mb-10'>*/}
        {/*  <p className='title'>Адрес</p>*/}
        {/*  <p className='text'>{data.address}</p>*/}
        {/*</div>*/}
        <div className='mb-10'>
          <p className='text'>{requestReason?.name}</p>
        </div>
        <div className='content-footer'>
          <p className='org-info'>
            <p className="city">{city?.value}</p>
            <span className='stick'/>
            {avatarData ? <img className="logo ml-5" src={avatarData.avatar} alt=''/> :
              <span className='logo'/>}
            <span className="name">{data.organization.name}</span>
          </p>
          <div className="stick"/>
          <p className='card-number'>№ {data.request_number}</p>
          <div className="stick"/>
          {data?.unread_comments > 0 &&
          <p className='comments' onClick={() => dispatch(setActivePanel('comment'))}>
            <CommentIcon/>{data.unread_comments}
          </p>}
        </div>
      </div>
      {data.pool.status > 1 && <div className={`card-footer ${cardFooterClass}`}>
        <p className="left">{Icon} {statusText}</p>
        <p className='right'>
          {data.er_call?.status === 2 || data.er_call?.status === 3 ?
            <p className="time">{brigade.eta} {brigade.delay ? `+ ${brigade.delay}` : ''} мин</p> : null}
          <span className='arrow'> {'>'} </span>
        </p>
      </div>}
    </div>
  )
}

export default Card
