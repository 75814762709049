import React, {ReactElement} from "react"
import './request-container.scss'

interface Props {
  title: string | ReactElement
  filter: ReactElement
  children: ReactElement[] | ReactElement
}

const RequestContainer = (props: Props) => {
  return (
    <div className='request-container'>
      <div className="header">
        <p className="title">{props.title}</p>
        <p className="filter-list">{props.filter}</p>
      </div>
      <div className="body">
        {props.children}
      </div>
    </div>
  )
}

export default RequestContainer
