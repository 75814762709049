import React, {FC, Fragment, ReactElement, useEffect, useRef, useState} from "react"
import ReactDOM from "react-dom";
import {CloseIcon} from "../../assets";
import './modal.scss'

interface Props {
  open: boolean
  cancelable?: boolean
  handleClose: (...args) => void
  title?: string | ReactElement
  className?: string
  closeIcon?: boolean
  small?: boolean
  children: any
}

// TODO: next sprint make this global usable function
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Modal: FC<Props> = (props) => {

  const modalRoot: any = document.querySelector('#modal-root');
  const {open} = props
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (props.open) {
      document.body.classList.add('overflow-hidden')
    } else if (modalRoot?.children.length === 0 && !props.open && prevOpen) {
      document.body.classList.remove('overflow-hidden')
    }
  })


  const data = <Fragment>
    {props.open &&
    <div id='modal-container'>
      <div className='modal-backdrop' onClick={props.handleClose}/>
      <div id='modal-content'
           className={`
           ${props.className ? props.className : ''}
           ${props.small ? 'small' : ''}
           `}>
        {props.closeIcon && <CloseIcon onClick={props.handleClose} className='close-icon'/>}
        {props.title ? <p className='modal-title'>{props.title}</p> : null}
        {props.children}
      </div>
    </div>}
  </Fragment>

  return (
    <Fragment>
      {modalRoot && ReactDOM.createPortal(
        data,
        modalRoot)}
    </Fragment>
  )
}

export default React.memo(Modal)
