import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {setApiError, togglePopover} from "../../admin/app-slice";
import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {Filter} from "./IUsers";
import {formValidator} from "./validator";
import {toBase64} from "../../services/utils";
import {omit} from 'lodash'

const initialState = {
  users: [],
  user: null,
  filter: {},
  organizationUuid: '',
  form: {
    last_name: '',
    first_name: '',
    middle_name: '',
    primary_phone: '',
    secondary_phone: '',
    mobile_phone: '',
    email: '',
    role: '',
    avatar: '',
    preview_avatar: '',
    formData: '',
  },
  validationFields: {} as any,
  formValid: false,
}

const user = createSlice({
  name: 'adminUsers',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload
      return state
    },
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload
      return state
    },
    resetForm: (state) => {
      state.form = initialState.form
      return state
    },
    setOrganizationUuid: (state, action: PayloadAction<any>) => {
      state.organizationUuid = action.payload
      return state
    },
    handleFormChange: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const data = action.payload
      state.form[data.key] = data.value
      return state
    },
    validateInputs: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((el => {
          state.validationFields = formValidator(state.validationFields, el)
        }))
      } else {
        state.validationFields = formValidator(state.validationFields, action.payload)
      }
      state.formValid = Object.keys(state.validationFields).every(k => state.validationFields[k] !== false);

      return state
    },
  }
});

export const {
  setUser,
  setUsers,
  resetForm,
  handleFormChange,
  validateInputs,
  setOrganizationUuid
} = user.actions;

export const createUser = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminUser = getState().adminUsers
    if (adminUser.formValid) {
      const data = {...adminUser.form}
      try {
        let response: any

        delete data.formData
        delete data.preview_avatar

        response = await api.admin.post('user', {
          ...data,
          organization_uuid: adminUser.organizationUuid
        })
        if(adminUser.form.formData) {
          const avatar = await dispatch(uploadAvatar(response.uuid))
          await api.admin.put(`user/${response.uuid}`, {avatar: avatar.data})
        }
        await dispatch(getUsers({organization_uuid: adminUser.organizationUuid}))
        await dispatch(clearForm())
        dispatch(togglePopover('adminAddUser'))
      } catch (e) {
        dispatch(setApiError(e))
      }
    }
  }
};

export const updateUser = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const adminUser = getState().adminUsers

    if (adminUser.formValid) {
      const data = omit({...adminUser.form}, ['preview_avatar'])
      try {
        if(data.formData) {
          const response = await dispatch(uploadAvatar(adminUser.user.uuid))
          data.avatar = response.data
        }
        delete data.formData
        await api.admin.put(`user/${adminUser.user.uuid}`, Object.assign({}, {
          ...data
        }))
        await dispatch(getUsers({organization_uuid: adminUser.organizationUuid}))
        dispatch(clearForm())
        dispatch(togglePopover('adminAddUser'))
      } catch (e) {
        dispatch(setApiError(e))
      }
    }
  }
};

export const getUsers = (filter?: Filter) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.admin.get('user', filter)
      dispatch(setUsers(res))
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const deleteUser = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.deleteUser(uuid)
      //TODO: some delete notification triggering
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const resendEmail = (email: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      return await api.admin.resendEmail({ email })
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const selectUser = (data) => {
  return (dispatch: AppDispatch) => {
    dispatch(setUser(data))
    if (data?.avatar) {
      dispatch(getAvatar(data.uuid, data.avatar))
    }
  }
}

export const addOrganizationUuid = (organizationUuid: string) => {
  return (dispatch: AppDispatch) => dispatch(setOrganizationUuid(organizationUuid))
}

export const clearForm = () => {
  return (dispatch: AppDispatch) => dispatch(resetForm())
}

export const uploadAvatar = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {form} = getState().adminUsers
    try {
      return await api.admin.upload(`user/avatar/${uuid}`, form.formData)
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getAvatar = (uuid: string, avatar: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const img = await api.admin.get(`user/avatar/${uuid}/${avatar}`)
      dispatch(handleFormChange({key: 'preview_avatar', value: `${toBase64(img.Body.data)}`}))
    } catch (e) {
      console.log("ERR", e)
    }
  }
}

export const formFields = Object.keys(initialState.form)

export default user.reducer;
