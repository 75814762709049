import React, {Fragment, useEffect, useState} from 'react'
import { checkPublicToken, declineOffer } from '../requests/requests-slice'
import { useAppDispatch, useQuery } from '../../core/hooks'

const BookingDecline = () => {
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)
  const query = useQuery();
  const token = query.get('token');
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(checkPublicToken(token as string))
      .then(res => {
        if(res) {
          dispatch(declineOffer(token as string))
            .then()
            .catch(error => {
              setTokenExpired(false)
            })
        } else {
          setTokenExpired(true)
        }
      })
      .catch(error => {
        setTokenExpired(true)
      })
  }, [dispatch, token])

  return (
    <div id='booking-decline-container' className='form-container'>
      {
        !tokenExpired ?
          <Fragment>
            <p className='description'>Уважаемый партнёр,</p>
            <p className='description mb-5'>Спасибо за ответ.</p>
            <p className='description'>Ваше отклонение было передано стрхавой компании.</p>
          </Fragment> :
          <Fragment>
            <p className='description'>Уважаемый партнёр,</p>
            <p className='description'>Запрос бронирование был отменен</p>
          </Fragment>
        }
    </div>
  )
}

export default BookingDecline
