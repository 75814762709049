import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { acceptBooking, checkPublicToken, getBookingOffer } from '../requests/requests-slice'
import { useAppDispatch, useQuery } from '../../core/hooks'
import { Autocomplete, Button, Input, Textarea } from '../../ui-components'
import { omit } from 'lodash'
import './styles.scss'
import { InfoText } from '../../shared'
import { DEPARTMENTS, PAYMENT_TYPES, ROOM_TYPES } from '../../services/constants'

const BookingAccept = () => {
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [bookingRequestData, setBookingRequestData] = useState<object>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [acceptSuccess, setAcceptSuccess] = useState<boolean>(false)
  const [department, setDepartment] = useState<string>('')
  const [roomType, setRoomType] = useState<string>('')
  const [paymentType, setPaymentType] = useState<string>('')
  const [phoneError, setPhoneError] = useState<boolean>(true)
  const [form, setForm] = useState<any>({
    booking_number: '',
    contact_phone: '',
    contact_name: '',
    room_type: '',
    payment_type: '',
    comment: '',
    department: ''
  })

  const query = useQuery()
  const token = query.get('token')
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(checkPublicToken(token as string))
      .then(res => {
        if (res) {
          dispatch(getBookingOffer(token as string))
            .then(bookingData => {
              if (bookingData) {
                setForm({
                  ...form,
                  request_uuid: bookingData.request_uuid,
                  hospital_uuid: bookingData.hospital_uuid,
                  token: token
                })
                setLoading(false)
                setBookingRequestData(bookingData)
              }
            })
            .catch(error => {
              setTokenExpired(false)
            })
        } else {
          setLoading(false)
          setTokenExpired(true)
        }
      })
      .catch(error => {
        setTokenExpired(true)
      })
  }, [dispatch, token])

  // handle input change event
  const handleInputChange = (event: ChangeEvent) => {
    const element = event?.target as HTMLInputElement
    switch (element.name) {
      case 'booking_number':
        setForm({
          ...form,
          booking_number: element.value
        })
        break
      case 'comment':
        setForm({
          ...form,
          comment: element.value
        })
        break
      case 'contact_phone':
        setForm({
          ...form,
          contact_phone: element.value
        })
        break
      case 'contact_name':
        setForm({
          ...form,
          contact_name: element.value
        })
        break
    }
  }

  // handle select change
  const handleSelectChange = (key: string, value: string) => {
    switch (key) {
      case 'department':
        setDepartment(value)
        setForm({
          ...form,
          department: DEPARTMENTS[Number(value) - 1].type
        })
        break
      case 'room_type':
        setRoomType(value)
        setForm({
          ...form,
          room_type: Number(value)
        })
        break
      case 'payment_type':
        setPaymentType(value)
        setForm({
          ...form,
          payment_type: Number(value)
        })
        break
    }
  }

  const validatePhone = (value) => {
    const checkedVal = value.replace(/-|_|'+'| |/g, "").length
    return checkedVal === 14
  }

  const isValid = (data) => {
    return Object.keys(data).every((k) => {
      if (k === 'contact_phone') {
        validatePhone(data[k]) ? setPhoneError(false) : setPhoneError(true)
        return validatePhone(data[k])
      }
      return data[k]
    })
  }

  const submitForm = () => {
    if (isValid(omit(form, ['comment']))) {
      dispatch(acceptBooking(form))
        .then(res => {
          if(res && res.accepted) {
            setAcceptSuccess(true)
          }
        })
    } else {
      setErrorMessage('*Заполните все поля.')
    }
  }

  const showContent = () => {
    if (loading) {
      return []
    } else if (!tokenExpired && !acceptSuccess) {
      return (
        <Fragment>
          <p className='title'>Запрос бронирования</p>
          <p className='description mb-20'>Пожалуйста подтвердите бронирования.</p>
          <div>
            <div className={'wrapper'}>
              <InfoText title='ФИО пациента' text={bookingRequestData['patient_name']}/>
            </div>
            <div className={'wrapper'}>
              <InfoText title='Возраст / Пол' text={bookingRequestData['age_gender']}/>
              <InfoText title='ОРВ/Температура' text={bookingRequestData['temperature']}/>
              <InfoText title='Тип палаты' text={bookingRequestData['type']}/>
            </div>
            <div className={'wrapper'}>
              <InfoText title='Диагноз: MKB810' text={bookingRequestData['diagnosis']}/>
            </div>
            <div className={'wrapper'}>
              <InfoText title='Профиль' text={bookingRequestData['department'] || 'Не выбран'}/>
            </div>
            {
              bookingRequestData['comments'] && <Textarea label={'Комментарии от Страховой Компании'} value={bookingRequestData['comments']}
                        readonly={true} className='mb-10 no-border' name={'comments'}/>
            }
            <div className="divider mb-30"/>
            <div className='wrapper flex-start mb-30'>
              <Autocomplete options={ROOM_TYPES}
                            isClearable={true}
                            error={!form.room_type}
                            onChange={(val) => handleSelectChange('room_type', val.key)}
                            optionLabel={'type'}
                            optionValue={'key'}
                            value={roomType} label="Тип Палаты"/>
              <Autocomplete className={'ml-20'}
                            options={PAYMENT_TYPES}
                            isClearable={true}
                            error={!form.payment_type}
                            onChange={(val) => handleSelectChange('payment_type', val.key)}
                            optionLabel={'type'}
                            optionValue={'key'}
                            value={paymentType} label="ОМС / ДМС"/>
            </div>
            <div className='wrapper flex-start mb-30'>
              <Autocomplete className={'department'}
                            options={DEPARTMENTS}
                            isClearable={true}
                            error={!form.department}
                            onChange={(val) => handleSelectChange('department', val.key)}
                            optionLabel={'type'}
                            optionValue={'key'}
                            value={department} label="Профиль"/>
            </div>
            <div className='wrapper flex-start mb-30'>
              <Input name='booking_number'
                     error={!form.booking_number}
                     onChange={handleInputChange}
                     value={form.booking_number} label="Номер брони"/>
              <Input className={'pl-20'}
                     name='contact_name'
                     error={!form.contact_name}
                     onChange={handleInputChange}
                     value={form.contact_name} label="Контактное имя"/>
              <InputMask className={'pl-20'}
                         mask="+7 (999) 999-99-99" maskChar=" "
                         name='contact_phone'
                         error={!form.contact_phone || phoneError}
                         onChange={(e) => e && handleInputChange(e)}
                         value={form.contact_number} label="Контактный номер">
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </div>
            <div className='wrapper flex-start mb-30'>
              <Input className={'full-width'}
                     name='comment'
                     onChange={handleInputChange}
                     value={form.comment} label="Добавить комментарии"/>
            </div>
          </div>
          <div className="divider mb-20"/>
          <Button color='gradient' onClick={submitForm} className='button mb-20' type='contained'>Отправить</Button>
          {errorMessage && <p className='text-error'>{errorMessage}</p>}
        </Fragment>
      )
    } else if(acceptSuccess) {
      return (
        <Fragment>
          <p className='description'>Уважаемый партнёр,</p>
          <p className='description mb-5'>Ваше предложение было передано страховой компании.</p>
          <p className='description'>Спасибо за сотрудничество.</p>
        </Fragment>
      )
    } else if(tokenExpired) {
      return (
        <Fragment>
          <p className='description'>Уважаемый партнёр,</p>
          <p className='description'>Запрос бронирование был отменен</p>
        </Fragment>
      )
    }
  }

  return (
    <div id='booking-decline-container' className='form-container'>
      {showContent()}
    </div>
  )
}

export default BookingAccept
