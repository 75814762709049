import React from 'react'
import CommentsPanel from "./comments-panel";
import {useAppSelector} from "../../core/hooks";
import HistoryPanel from "./history-panel";
import {
  New,
  WaitingApprove,
  Accept,
  AssignBrigade,
  OnWay,
  Arrived,
  Closed,
  Hospitalization,
  BookingApproved,
  OnWayToHospital,
  Stationing
} from './request-components'

const Content = () => {
  const {activePanel, pool, er_call} = useAppSelector(state => state.requests)

  let Content: any
  if (activePanel === 'data') {
    switch (pool.status) {
      case 1:
        Content = <New/>
        break
      case 2:
        Content = pool.approved_by ? <Accept/> : <WaitingApprove/>
        break
      default:
        Content = <New/>
    }

    if (er_call) {
      switch (er_call.status) {
        case 1:
          Content = <AssignBrigade/>
          break;
        case 2:
        case 3:
          Content = <OnWay/>
          break;
        case 4:
          Content = <Arrived/>
          break;
        case 5:
        case 6:
          Content = <Closed/>
          break;
        case 7:
        case 8:
        case 16:
          Content = <Hospitalization/>
          break;
        case 9:
          Content = <BookingApproved/>
          break;
        case 10:
        case 22:
          Content = <OnWayToHospital/>
          break;
        case 11:
          Content = <Stationing/>
          break;
        case 12:
        case 17:
        case 18:
          Content = <Closed/>
          break;
        case 13:
        case 14:
        case 19:
        case 20:
        case 21:
          Content = <OnWay/>
          break;
        case 15:
          Content = <New/>
          break;
        default:
          Content = ''
      }
    }
  } else if (activePanel === 'comment') {
    Content = <CommentsPanel/>
  } else {
    Content = <HistoryPanel/>
  }

  return (Content)
}

export default Content
