import React, {createRef, useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom';
import './insurance-dashboard.scss'
import {Panel, PanelHeader, PanelPopover, PrimaryModal, RequestContainer} from "../../shared";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {attachAvatars, setData, toggleEditRequest, toggleMainPanel, toggleRequestForm} from '../../app/app-slice';
import {NoData} from '../../shared'
import Card from './card'
import emptyCardImg from '../../assets/images/hospital-img-3.png'
import emptyCardImg1 from '../../assets/images/hospital-img-1.png'
import emptyCardImg2 from '../../assets/images/hospital-img-2.png'
import {PlusIcon, ArrowDownIcon, ErrorIconGradient, ArrowRightIcon, CheckmarkIcon, LinkIcon} from "../../assets";
import {Button} from "../../ui-components";
import {getInsuranceRequestsList, getSingleRequest} from "../requests/insurance-thunk"
import Content from "../insurance-request/content";
import Header from "../insurance-request/header";
import SocketContext from "../../core/socket-context";
import RequestForm from "../requests/request-form";
import {TooltipBlue, ProblemTooltip} from "../../shared/tooltips"
import {
  filterRequestsByType,
  filterRequestsByErStatus,
  resetForm,
  filterRequestsByStatus
} from "../requests/requests-slice";
import ReactTooltip from "react-tooltip";
import {APPLICATIONS_FILTER, INSURANCE_ER_FILTER, HOSPITALIZATION_FILTER} from '../../services/constants';
import { buildProblemsAmountMessage } from '../../services/utils';
import {checkConnections} from "../user/user-slice";
import { playNotificationSound } from '../notification-sound-manager/notifications-sound-manager-slice'


const InsuranceDashboard = () => {
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext);
  const app = useAppSelector(state => state.app)
  const {requests, request} = useAppSelector(state => state.requests)
  const [applicationsFilterActive, setApplicationsFilterActive] = useState(false)
  const [erCallsFilterActive, setErCallsFilterActive] = useState(false)
  const [hospitalizationsFilterActive, setHospitalizationsFilterActive] = useState(false)
  const [applicationsFilterSelected, setApplicationsFilterSelected] = useState<string>('')
  const [erCallsFilterSelected, setErCallsFilterSelected] = useState<string>('')
  const [hospitalizationsFilterSelected, setHospitalizationsFilterSelected] = useState<string>('')
  const token = localStorage.getItem('token')
  const user = localStorage.getItem('user')
  const history = useHistory()

  if (!user || !token) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    history.push('/login')
  }

  useEffect(() => {
    dispatch(setData({prop: 'pageTitle', data: 'Главная'}))
    dispatch(getInsuranceRequestsList())
      .then(() => dispatch(attachAvatars()))
  }, [history.location])

  useEffect(() => {
    socket.on('dashboard_update', (e) => {
      dispatch(getInsuranceRequestsList(e.uuid))
    })
  }, [])

  useEffect(() => {
    socket.on('no_pool', (data) => {
      dispatch(setData({data, prop: 'noPoolRequest'}))
      dispatch(setData({data: true, prop: 'noPoolModelOpen'}))
    })
  })

  const problematicApplications = requests.applications.filter(data => data.problematic === true)
  const problematicErCalls = requests.er_calls.filter(data => data.problematic === true)
  const problematicHospitalization = requests.hospitalization.filter(data => data.problematic === true)

  const filterRequests = (column, value, filterType) => {
    switch (filterType) {
      case 'applications':
        dispatch(filterRequestsByStatus({column, value}))
        break
      default:
        dispatch(filterRequestsByErStatus({column, value}))
        break
    }
  }

  let applicationsTooltipRef: any = createRef()
  let erCallsTooltipRef: any = createRef()
  let hospitalizationTooltipRef: any = createRef()

  return (
    <div id='dashboard'>
      <RequestContainer
        title={
          <>
            Заявки и предложения
            {problematicApplications.length > 0 &&
            <ProblemTooltip dataFor={'applications'}
                            onClick={() => {
                              if(applicationsFilterActive && applicationsFilterSelected === 'Проблематичные') {
                                setApplicationsFilterActive(false)
                                setApplicationsFilterSelected('')
                               return filterRequests('applications', 0, 'applications')
                              }
                              filterRequests('applications', true, 'applications')
                              setApplicationsFilterActive(true)
                              setApplicationsFilterSelected('Проблематичные')
                            }}
                            text={`${problematicApplications.length} ${buildProblemsAmountMessage(problematicApplications.length)}`}/>}
          </>}
        filter={
          <>
            <span data-tip
                  data-event='click'
                  data-for='applications-filter'
                  className={`filter-btn ${applicationsFilterActive ? 'filter-active' : ''}`}>
              Фильтр{applicationsFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
            <TooltipBlue offsetLeft={130} ref={applicationsTooltipRef} id='applications-filter'>
              {APPLICATIONS_FILTER.map((el, i) =>
                <p key={i} className={`filter-item ${applicationsFilterSelected === el.text ? 'active' : ''}`}
                   onClick={() => {
                     filterRequests('applications', el.value, 'applications')
                     setApplicationsFilterActive(true)
                     setApplicationsFilterSelected(el.text)
                     applicationsTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text} {applicationsFilterSelected === el.text && <CheckmarkIcon/>}</p>
              )}
            </TooltipBlue>
          </>
        }>
        {!requests.applications.length ?
          <>
            <NoData img={emptyCardImg}
                    title={'Создайте заявку чтобы начать процесс и заявки от СМП'}/>
            <Button type='contained'
                    color='secondary'
                    onClick={() => {
                      dispatch(checkConnections())
                        .then(res => {
                          if(!res.length) {
                            dispatch(setData({data: true, prop: 'noConnectionModalOpen'}))
                          } else {
                            dispatch(resetForm())
                            dispatch(toggleRequestForm())
                          }
                        })
                    }}
                    className='mt-20'
                    startIcon={<PlusIcon/>}>Создать Заявку</Button>
          </> :
          <>
            {
              requests.applications.map((data) => data.visible &&
                <Card key={data.uuid} onClick={() => {
                  dispatch(getSingleRequest(data.uuid))
                }} data={data} column='applications'/>)
            }
          </>
        }
      </RequestContainer>
      <RequestContainer
        title={
          <>
            В исполнении
            {problematicErCalls.length > 0 &&
            <ProblemTooltip dataFor={'er_calls'}
                            onClick={() => {
                              if(erCallsFilterActive && erCallsFilterSelected === 'Проблематичные') {
                                setErCallsFilterActive(false)
                                setErCallsFilterSelected('')
                                return filterRequests('er_calls', 0, 'er_calls')
                              }
                              filterRequests('er_calls', true, 'er_calls')
                              setErCallsFilterActive(true)
                              setErCallsFilterSelected('Проблематичные')
                            }}
                            text={`${problematicErCalls.length} ${buildProblemsAmountMessage(problematicErCalls.length)}`}/>}
          </>}
        filter={
          <>
            <span data-tip
                  data-event='click'
                  data-for='er-calls-filter'
                  className={`filter-btn ${erCallsFilterActive ? 'filter-active' : ''}`}>
              Фильтр{erCallsFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
            <TooltipBlue ref={erCallsTooltipRef} offsetLeft={130} id='er-calls-filter'>
              {INSURANCE_ER_FILTER.map((el, i) =>
                <p key={i} className={`filter-item ${erCallsFilterSelected === el.text ? 'active' : ''}`}
                   onClick={() => {
                     filterRequests('er_calls', el.value, 'er_calls')
                     setErCallsFilterActive(true)
                     setErCallsFilterSelected(el.text)
                     erCallsTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text} {erCallsFilterSelected === el.text && <CheckmarkIcon/>}</p>
              )}
            </TooltipBlue>
          </>
        }>
        {!requests.er_calls.length ?
          <>
            <NoData img={emptyCardImg1}
                    title={'Процесс от СМП будет показан в этой секции'}/>
          </> :
          <>
            {
              requests.er_calls.map((data) => data.visible &&
                <Card key={data.uuid} onClick={() => {
                  dispatch(getSingleRequest(data.uuid))
                }} data={data} column='er_calls'/>)
            }
          </>
        }
      </RequestContainer>
      <RequestContainer
        title={
          <>
            Госпитализация
            {problematicHospitalization.length > 0 &&
            <ProblemTooltip dataFor={'hospitalization'}
                            onClick={() => {
                              if(hospitalizationsFilterActive && hospitalizationsFilterSelected === 'Проблематичные') {
                                setHospitalizationsFilterActive(false)
                                setHospitalizationsFilterSelected('')
                                return filterRequests('hospitalization', 0, 'er_calls')
                              }
                              filterRequests('hospitalization', true, 'er_calls')
                              setHospitalizationsFilterActive(true)
                              setHospitalizationsFilterSelected('Проблематичные')
                            }}
                            text={`${problematicHospitalization.length} ${buildProblemsAmountMessage(problematicHospitalization.length)}`}/>}
          </>}
        filter={
          <>
            <span data-tip
                  data-event='click'
                  data-for='hospitalization-filter'
                  className={`filter-btn ${hospitalizationsFilterActive ? 'filter-active' : ''}`}>
              Фильтр{hospitalizationsFilterActive ? <ArrowRightIcon/> : <ArrowDownIcon/>}</span>
            <TooltipBlue offsetLeft={130} ref={hospitalizationTooltipRef} id='hospitalization-filter'>
              {HOSPITALIZATION_FILTER.map((el, i) =>
                <p key={i} className={`filter-item ${hospitalizationsFilterSelected === el.text ? 'active' : ''}`}
                   onClick={() => {
                     filterRequests('hospitalization', el.value, 'er_calls')
                     setHospitalizationsFilterActive(true)
                     setHospitalizationsFilterSelected(el.text)
                     hospitalizationTooltipRef.current.tooltipRef = null
                     ReactTooltip.hide()
                   }}>{el.text} {hospitalizationsFilterSelected === el.text && <CheckmarkIcon/>}</p>
              )}
            </TooltipBlue>
          </>
        }>
        {!requests.hospitalization.length ?
          <>
            <NoData img={emptyCardImg2}
                    title={'Запросы о госпитализации видны здесь'}/>
          </> :
          <>
            {
              requests.hospitalization.map((data) => data.visible &&
                <Card key={data.uuid} onClick={() => {
                  dispatch(getSingleRequest(data.uuid))
                }} data={data} column='hospitalization'/>)
            }
          </>
        }
      </RequestContainer>
      <Panel handleClose={() => dispatch(toggleMainPanel())} open={app.mainPanelOpen}>
        <PanelHeader>
          <Header handleClose={() => dispatch(toggleMainPanel())}/>
        </PanelHeader>
        {request?.parent_request &&
        <p className="connected-request mb-20"
           onClick={() => dispatch(getSingleRequest(request.parent_request, false))}>
          <LinkIcon/>{`Связ. заявка № ${request?.parent_request_number}`}
        </p>}
        <Content/>
        <PanelPopover open={app.popoverFormStatuses.editRequestOpen}
                      handleClose={() => dispatch(toggleEditRequest())}
                      title={'Изменить данные пациента'}>
          <RequestForm/>
        </PanelPopover>
      </Panel>
    </div>
  )
}

export default React.memo(InsuranceDashboard)
