import {combineReducers} from "@reduxjs/toolkit";
import requestsReducer from '../modules/requests/requests-slice'
import userReducer from '../modules/user/user-slice'
import notificationsReducer from '../modules/notifications/notifications-slice'
import appReducer from '../app/app-slice'
import historyReducer from '../modules/left-panel/history/history-slice'
import rejectsReducer from '../modules/left-panel/er-rejects/er-rejects-slice'
import activeTicketsReducer from "../modules/left-panel/active-tickets/active-tickets-slice"
import pendingTicketsReducer from "../modules/left-panel/pending-tickets/pending-tickets-slice"
import dispatchersReducer from "../modules/dispatchers/dispatchers-slice"
import insurancePartnershipsReducer from "../modules/partnership/insurance/insurance-partnership-slice"
import emergencyPartnershipsReducer from "../modules/partnership/emergency/emergency-partnership-slice"
import insuranceCanceledReducer from "../modules/left-panel/insurance-canceled/insurance-cenceled-slice"
import notificationSoundSlice from '../modules/notification-sound-manager/notifications-sound-manager-slice'

import adminOrganizationReducer from '../admin-modules/organization/organization-slice'
import adminUsersReducer from '../admin-modules/users/users-slice'
import adminAppReducer from '../admin/app-slice'
import adminEmergencyReducer from '../admin-modules/emergency/emergency-slice'
import adminInsuranceReducer from '../admin-modules/insurance/insurance-slice'
import adminDiagnosisReducer from '../admin-modules/diagnosis/diagnosis-slice'
import adminHospitalsReducer from '../admin-modules/hospitals/hospitals-slice'
import adminReasonsReducer from '../admin-modules/reasons/reasons-slice'


const combinedReducers = combineReducers({
  requests: requestsReducer,
  user: userReducer,
  notifications: notificationsReducer,
  app: appReducer,
  history: historyReducer,
  rejectedOffers: rejectsReducer,
  activeTickets: activeTicketsReducer,
  pendingTickets: pendingTicketsReducer,
  dispatchers: dispatchersReducer,
  insurancePartnerships: insurancePartnershipsReducer,
  emergencyPartnerships: emergencyPartnershipsReducer,
  canceled: insuranceCanceledReducer,
  notificationSound: notificationSoundSlice,

  // admin
  adminOrganization: adminOrganizationReducer,
  adminApp: adminAppReducer,
  adminUsers: adminUsersReducer,
  adminEmergency: adminEmergencyReducer,
  adminInsurance: adminInsuranceReducer,
  adminDiagnosis: adminDiagnosisReducer,
  adminHospitals: adminHospitalsReducer,
  adminReasons: adminReasonsReducer
});

export default combinedReducers;
