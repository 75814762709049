import Organization from "./organization"
import Request from "./request"
import User from './user'
import Admin from './admin'
import Notification from './notification'
import ErCall from './erCall'
import Static from "./static"
import Comments from './comments'
import Activities from "./activities"
import BookingRequest from "./bookingRequest"
import Partnerships from './partnerships'

const Instances = {
  Organization,
  Request,
  User,
  Admin,
  Notification,
  ErCall,
  Static,
  Comments,
  Activities,
  BookingRequest: BookingRequest,
  Partnerships
}

export default Instances
