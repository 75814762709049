import {AppDispatch} from "../../core/store";
import {ApiInstance} from "../../services/api";
import {setApiError} from "../../admin/app-slice";
import {toggleMainPanel, togglePopover} from "../../app/app-slice";
import {
  createRequest,
  getAvatar,
  getMessages, setErRequest,
  setRequest,
  setRequestForm,
  setRequestsList, validateInputs
} from './requests-slice'
import {toBase64, getRegion} from "../../services/utils";
import {ER_CALL_STATUSES} from "../../services/constants";
import {getErRequestsList} from "./er-thunk";

export const getInsuranceRequestsList = (uuid?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const { activeRegion, mainPanelOpen } = getState().app
      const {request} = getState().requests
      const region = getRegion() || activeRegion.id !== 1 ? activeRegion.id : ''
      const res = await api.request.get('/insurance-dashboard-list', {region})

      dispatch(setRequestsList(res))
      if(uuid && uuid === request.uuid && mainPanelOpen) {
        dispatch(getSingleRequest(uuid, false))
      }
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const getSingleRequest = (request_uuid: string, togglePanel: boolean = true) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.request.get(`/insurance-single/${request_uuid}`)
      dispatch(setRequest(res))
      togglePanel && dispatch(toggleMainPanel())
      res.er_call && await dispatch(getMessages())

      const organization = {...res.emergency_org}
      if (res.emergency_org?.avatar) {
        const file = await dispatch(getAvatar(organization.uuid, organization.avatar))
        organization.avatar = toBase64(file.Body.data)
      }
      const updatedRes: any = {...res, emergency_org: organization}

      dispatch(setRequest(updatedRes))

      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const approveOffer = (request_uuid: string, executor_uuid: string, brigade_uuid: string, contact_number: any, policy_number: string, sex: number | null, notes: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    try {
      const res = await api.request.post(`insurance-approve/${request_uuid}`, {executor_uuid, brigade_uuid, contact_number, policy_number, sex, notes})
      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      dispatch(toggleMainPanel())
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

/*export const updatePatientInfo = (contact_number: any, policy_number: string, sex: number | null, notes: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {user} = getState().user
    const {request} = getState().requests

    try {
      return await api.request.put(`patient/${request.uuid}/${user.organization.type}`, {contact_number, policy_number, sex, notes})
    } catch (e) {
      console.log('err', e)
      dispatch(setApiError(e))
    }
  }
}*/

export const closeRequest = (rejectReason: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      await api.request.post(`close-request/${request.uuid}`, {rejection_reason: rejectReason})
      dispatch(getInsuranceRequestsList())
      dispatch(toggleMainPanel())
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const closeAndCreateNewRequest = (rejectReason: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const res = await dispatch(closeRequest(rejectReason))
    if (res) {
      await dispatch(setRequestForm())
      await dispatch(createRequest(false))
    }
  }
}

export const assignHospital = (hospital_uuid: string, comment?: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(er_call.uuid, {hospital_uuid, hospital_comment: comment, status: ER_CALL_STATUSES.bookingApproved})
      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const changeDispatcher = (dispatcher_uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api.request.put(`/change-insurance-dispatcher/${request.uuid}`, {dispatcher_uuid})
      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const changeErCallStatus = (status: number) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests
    try {
      const res = await api['er-call'].put(er_call.uuid, {status})

      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const sendBookingRequest = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {hospitalizationForm, request} = getState().requests
    try {
      const res = await api['booking-request'].post('', {
        ...hospitalizationForm,
        request_uuid: request.uuid,
        temperature: hospitalizationForm.temperature === 1,
        hospitalUUIDs: hospitalizationForm.hospital_ids
      })

      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      dispatch(togglePopover('hospitalsListOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const acceptBooking = (uuid: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api['booking-request'].post('approve-booking', {
        request_uuid: request.uuid,
        booking_response_uuid: uuid
      })
      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      dispatch(togglePopover('hospitalsOffersListOpen'))
      //TODO: some approve notification trigger
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const cancelBookings = () => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {request} = getState().requests
    try {
      const res = await api['booking-request'].post('cancel-bookings', {
        request_uuid: request.uuid,
      })

      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      dispatch(togglePopover('hospitalsOffersListOpen'))
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}

export const replaceHospital = (hospital_comment: string) => {
  return async (dispatch: AppDispatch, getState: any, api: ApiInstance) => {
    const {er_call} = getState().requests

    try {
      //const res = await api['er-call'].post(`request-transfer-hospital/${er_call.uuid}`, {status: ER_CALL_STATUSES.hospitalizationRequest, hospital_comment})
      const res = await api['er-call'].put(er_call.uuid, {status: ER_CALL_STATUSES.hospitalizationRequest, hospital_comment})
      dispatch(setRequest(res))
      dispatch(getInsuranceRequestsList())
      return true
    } catch (e) {
      dispatch(setApiError(e))
    }
  }
}
